import React from 'react'
import './privacy.css'
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'

const PrivacyPolicy = () => {
  return (
    <>
    <Navbar />

    <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='backend__bannerTitle'>
                    <span className='block text-center'>Privacy and Policy</span>
                </div>
            </div>        
        </div>


      <section className='privacy&policy mx-auto'>
        <div className='lg:flex justify-center px-5 md:px-10'>
            <div className='lg:w-1/3 py-5 md:py-10 lg:block'>
              <div className='lg:sticky top-0 pr-5'>
                <div className='privacy_head2'>Table of contents</div>
                  <div className='privacy_para'>
                    <div className=' py-2 sm:py-3'><a href="#InformationCollect" className='hover:underline hover:text-blue-500'>1.Information we collect</a></div>
                    <div className=' py-2 sm:py-3'><a href="#UseInformation" className='hover:underline hover:text-blue-500'>2.Our Use Of Your Information.</a></div>
                    <div className=' py-2 sm:py-3'><a href="#HowInformation" className='hover:underline hover:text-blue-500'>3.How Information Is Collected</a></div>
                    <div className=' py-2 sm:py-3'><a href="#Cookies" className='hover:underline hover:text-blue-500'>4.Cookies</a></div>
                    <div className=' py-2 sm:py-3'><a href="#GoogleAnalytics" className='hover:underline hover:text-blue-500'>5.Google Analytics</a></div>
                    <div className=' py-2 sm:py-3'><a href="#ExternalWebsite" className='hover:underline hover:text-blue-500'>6.External Links On The Website</a></div>
                    <div className=' py-2 sm:py-3'><a href="#YourRights" className='hover:underline hover:text-blue-500'>7.Your Rights</a></div>
                    <div className=' py-2 sm:py-3'><a href="#Confidentiality" className='hover:underline hover:text-blue-500'>8.Confidentiality</a></div>
                    <div className=' py-2 sm:py-3'><a href="#OtherInformation" className='hover:underline hover:text-blue-500'>9.Other Information Collectors</a></div>
                    <div className=' py-2 sm:py-3'><a href="#OurInformation" className='hover:underline hover:text-blue-500'>10.Our Disclosure Of Your Information</a></div>
                    <div className=' py-2 sm:py-3'><a href="#YourProfile" className='hover:underline hover:text-blue-500'>11.Accessing, Reviewing And Changing Your Profile.</a></div>
                    <div className=' py-2 sm:py-3'><a href="#Security" className='hover:underline hover:text-blue-500'>12.Security</a></div>
                    <div className=' py-2 sm:py-3'><a href="#Severability" className='hover:underline hover:text-blue-500'>13.Severability</a></div>
                    <div className=' py-2 sm:py-3'><a href="#Amendment" className='hover:underline hover:text-blue-500'>14.Amendment</a></div>
                    <div className=' py-2 sm:py-3'><a href="#DataDownload&DataRemoval" className='hover:underline hover:text-blue-500'>15.Consent Withdrawal, Data Download & Data Removal Requests</a></div>
                  </div>
                
              </div>
            </div>
        
            <div className='lg:w-2/3 xl:w-1/2 py-10'>
                
                    <div className='privacy_para'>
                        <div className='privacy_head2 pb-2 md:pb-5'>Welcome to Haoda Privacy and Policy.</div>
                        <div className='py-2'>This legal agreement is an electronic record in terms of the Indian Information Technology Act, 2000 and rules there under as applicable and the amended provisions about electronic rec-ords in various statutes as amended by the Indian Information Technology Act, 2000. This electronic record is generated by a computer system and does not require any physical or digital signatures.</div>
                        <div className='py-2'>This legal document is published in accordance with the provisions of Rule 3 (1) of the Indian Information Technology (Intermediaries guidelines) Rules, 2011 and Rule 4 of the Infor-mation Technology (Reasonable security practices and procedures and sensitive personal data or information) Rules, 2011 of Information Technology Act, 2000 amended through In-formation Technology Amendment Act, 2008 that require publishing the Terms of Use and practices for access and usage of any functional website.</div>
                        <div className='py-2'>This Website is created and operated by M/s Haoda Payment Solutions Private Limited, (hereinafter referred to as “We”, “Our”, and “Us”) and operating under the brand name ‘MyPayHaoda’ (“Brand name”). The creator of this Privacy Policy ensures a steady commit-ment to Your privacy with regard to the protection of your invaluable information that you may share across on this Website. This privacy policy contains information about the Website “www.mypayhaoda.com”. (“Website”).</div>
                        <div className='py-2'>For the purpose of these Privacy Policy (“Privacy Policy”), wherever the context so requires, “We”, “Our”, and “Us” shall mean and refer to the Website. “You”, “Your”, “Yourself”, “User” shall mean and refer to natural and legal individuals and firms who shall be users of this website provided by us and who is competent to enter into binding contracts, as per Indian laws. “Third Parties” refer to any website, platform or individual apart from the Users and the creator of this Website.</div>
                    </div>
                    <div id='InformationCollect' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>1.Information we collect</div>
                        <div className='privacy_para py-2'>We are committed to respecting Your online privacy. We further recognize Your need for appro-priate protection and management of any Personal Information You share with us. We may col-lect the following information:</div>
                        <ol className='pl-8 sm:pl-14 privacy_para'>
                            <li className='list-decimal py-2 '>Registration data such as full name, email ID and phone number.</li>
                            <li className='list-decimal py-2'>KYC details and monitored transaction details. This information may include User feedback and any other information as required to RBI for any inquires to prevent to prevent fraudulent activities.</li>
                            <li className='list-decimal py-2'>Tracking Information such as, but not limited to the IP address of your device and Device ID when connected to the Internet. This information may include the URL that you just came from (whether this URL is on the Website or not), which URL you next go to (whether this URL is on the Website or not), your computer or device browser information, and other information associated with your interaction with the Website.</li>
                        </ol>
                        <div className='privacy_para py-2 sm:py-5'>This privacy policy also applies to data we collect from users who are not registered as members of this Website, including, but not limited to, browsing behaviour, pages viewed etc. We also collect and store personal information provided by You from time to time on the Website. We only collect and use such information from you that we consider necessary for achieving a seam-less, efficient and safe experience, customized to your needs including:</div>
                       <ol className='pl-8 sm:pl-14 paragraph py-3'>
                           <li className='list-decimal py-2'>To enable the provision of services opted for by you</li>
                           <li className='list-decimal py-2'>To enable the viewing of content in your interest;</li>
                           <li className='list-decimal py-2'>To communicate the necessary account and service-related information from time to time;</li>
                           <li className='list-decimal py-2'>To allow you to receive quality customer care services and data Collection;</li>
                           <li className='list-decimal py-2'>To comply with applicable laws, rules and regulations;</li>
                        </ol>
                        <div className='py-2 privacy_para'>To the extent possible, we provide You with the option of not divulging any specific information that you wish for us not to collect, store or use. You may also choose not to use a particular ser-vice or feature on the Website and opt-out of any non-essential communications from the Web-site.</div>
                        <div className='privacy_para py-2'>Further, transacting over the internet has inherent risks which can only be avoided by you fol-lowing security practices yourself, such as not revealing account/login related information to any other person and informing our customer care team about any suspicious activity or where your account has/may have been compromised.</div>
                    </div>

                    <div id='UseInformation' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>2.Our Use Of Your Information.</div>
                        <div className='privacy_para'>The information provided by you shall be used to provide and improve the service for you and all users.</div>
                        <ul className='pl-8 sm:pl-14 privacy_para'>
                            <li className='list-disc py-2'>To provide you with services on your request.</li>
                            <li className='list-disc py-2'>For maintaining an internal record.</li>
                            <li className='list-disc py-2'>For enhancing the Services provided.</li>
                            <li className='list-disc py-2'>For maintaining record under the legal and statutory provisions.</li>
                        </ul>
                        <div className='py-3 privacy_para'>For more details about the nature of such communications, please refer to our Terms of Service. Further, your data and Sensitive Personal data may be collected and stored by Us for internal record.</div>
                        <div className='py-3 privacy_para'>We use Your tracking information such as IP addresses, and or Device ID to help identify you and to gather broad demographic information and make further services available to you. We will not sell, license or trade Your personal information. We will not share your personal in-formation with others unless they are acting under our instructions or we are required to do so by law.</div>
                        <div className='py-3 privacy_para'>Information collected via Our server logs includes users' IP addresses and the pages visited; this will be used to manage the web system and troubleshoot problems. We also use third-party ana-lytics, tracking, optimization and targeting tools to understand how users engage with our Web-site so that we can improve it and cater personalized content/ads according to their preferences.</div>
                    </div>

                    <div id='HowInformation' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>3.How Information Is Collected</div>
                        <div className='privacy_para py-2'>Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. If the same is not identified to you, you have the right to request the Company to elucidate the purpose of collection of said personal information, pending the fulfilment of which you shall not be mandated to disclose any information whatsoever.</div>
                        <div className='privacy_para py-2'>We will collect and use your personal information solely to fulfil those purposes specified by us, within the scope of the consent of the individual concerned or as required by law. We will only retain personal information as long as necessary for the fulfilment of those purposes. We will collect personal information by lawful and fair means and with the knowledge and consent of the individual concerned.</div>
                        <div className='privacy_para py-2'>Personal data should be relevant to the purposes for which it is to be used, and, to the extent nec-essary for those purposes, should be accurate, complete, and up-to-date.</div>
                    </div>

                    <div id='Cookies' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>4.Cookies</div>
                        <div className='privacy_para py-2'>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer if you agree. By continuing to browse the site, you are agreeing to our use of cookies. Cookies contain information that is transferred to your computer’s hard drive. You can set your browser to refuse all or some browser cookies, or to alert you when websites set or ac-cess cookies. If you disable or refuse cookies, please note that some parts of this website may become inaccessible or not function properly. A list of the type of cookies we use is as follows:</div>
                        <ul className='pl-8 sm:pl-14 privacy_para'>
                            <li className='list-disc py-2'><span className='font-semibold'>1.Strictly necessary cookies:</span> These are cookies that are required for the operation of our web-site. They include, for example, cookies that enable you to log into secure areas of our web-site, use a shopping cart or make use of e-billing services.</li>
                            <li className='list-disc py-2'><span className='font-semibold'>2. Analytical/performance cookies:</span><span> They allow us to recognize and count the number of visi-tors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</span></li>
                            <li className='list-disc py-2'><span className='font-semibold'>3. Functionality cookies:</span>These are used to recognize you when you return to our website. This enables us to personalize our content for you, greet you by name and remember your prefer-ences (for example, your choice of language or region).</li>
                            <li className='list-disc py-2'><span className='font-semibold'>4. Targeting cookies:</span> These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this infor-mation with third-parties for this purpose.</li>
                        </ul>
                        <div className='py-3 privacy_para'>Please note that third-parties (including, for example, advertising networks and providers of ex-ternal services like web traffic analysis services) may also use cookies, over which we have no control. These cookies are likely to be analytical/performance cookies or targeting cookies. You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including essential cookies) you may not be able to access all or parts of our website.</div>
                    </div>

                    <div id='GoogleAnalytics' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>5.Google Analytics</div>
                        <div className='paragraph py-2'>We use Google Analytics to help us to understand how you make use of our content and work out how we can make things better. These cookies may follow all visitors of the Platform and collect anonymous data on where they have come from, which pages they visit, and how long they spend on the site. This data is then stored by Google to create reports. These cookies do not store personal data or business data. The information generated by the cookie about the use of the Website, including IP address, may be transmitted to, and stored by Google on servers in the United States. Google may use this information to evaluate the visitor’s use of the website, com-piling reports on website activity for us and providing other services relating to website activity and internet usage. Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google's behalf. Google will not associate any IP address’ with any other data held by Google. By using this website, all visi-tor’s consent to the processing of data by Google in the manner and for the purposes set out above. The Google website contains further information about Analytics and a copy of Google's privacy policy pages.</div>
                    </div>

                    <div id='ExternalWebsite' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>6.External Links On The Website</div>
                        <div className='privacy_para py-2'>The Website may include advertisements, hyperlinks to other Websites or resources. We have no control over any other website or resources or contents available on these other websites, which are provided by companies or persons other than Us. You acknowledge and agree that we are not responsible for the availability of any such external sites or resources, and do not endorse any advertising, services or other materials on or available from such Website or resources. You acknowledge and agree that We are not liable for any loss or damage which may be incurred by you as a result of the availability of those external sites or resources, or as a result of any reliance placed by you on the completeness, accuracy or existence of any advertising, services or other materials on, or available from, such Websites. These external third-party websites and resource providers may have their privacy policies governing the collection, storage, retention and disclo-sure of Your Personal Information that You may be subject to. We recommend that you enter the Website and review their privacy policy.</div>
                    </div>

                    <div id='YourRights' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>7.Your Rights</div>
                        <div className='privacy_para py-2'>Unless subject to an exemption, you have the following rights concerning your data:</div>
                        <ul className='pl-8 sm:pl-14 privacy_para'>
                            <li className='list-disc py-2'>The right to request a copy of your data which we hold about you;</li>
                            <li className='list-disc py-2'>The right to request for any correction to any personal data if it is found to be inac-curate or out of date;</li>
                            <li className='list-disc py-2'>The right to withdraw Your consent to the processing at any time;</li>
                            <li className='list-disc py-2'>The right to object to the processing of personal data;</li>
                            <li className='list-disc py-2'>The right to complain about a supervisory authority.</li>
                            <li className='list-disc py-2'>The right to obtain information as to whether personal data are transferred to a third country or an international organization.</li>
                        </ul>
                        <div className='py-3 privacy_para'>Where you hold an account with any of our services, you are entitled to a copy of all personal data which we hold concerning you. You are also entitled to request that we restrict how we use your data in your account when you log in.</div>
                    </div>

                    <div id='Confidentiality' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>8.Confidentiality</div>
                        <div className='privacy_para py-2'>You further acknowledge that the Website may contain information that is designated confiden-tial by us and that you shall not disclose such information without our prior written consent. Your information is regarded as confidential and therefore will not be divulged to any third par-ty, unless if legally required to do so to the appropriate authorities. We will not sell, share, or rent your personal information to any third party or use your e-mail address for unsolicited mail. Any emails sent by us will only be in connection with the provision of agreed services, and you retain sole discretion to seek for discontinuation of such communications at any point in time.</div>
                    </div>

                    <div id='OtherInformation' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>9.Other Information Collectors</div>
                        <div className='privacy_para py-2'>Except as otherwise expressly included in this Privacy Policy, this document only addresses the use and disclosure of information we collect from you. To the extent that you disclose your in-formation to other parties, whether they are on our Website or other sites throughout the Internet, different rules may apply to their use or disclosure of the information you disclose to them. To the extent that we use third party advertisers, they adhere to their privacy policies. Since we do not control the privacy policies of the third parties, you are subject to ask questions before you disclose your personal information to others.</div>
                    </div>

                    <div id='OurInformation' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>10.Our Disclosure Of Your Information</div>
                        <div className='privacy_para py-2'>We may host surveys for survey creators for our Website who are the owners and users of your survey responses. We do not own or sell your responses. Anything you expressly disclose in your responses will be disclosed to survey creators. Please contact the survey creator directly to better understand how they might share your survey responses.Information collected will not be considered as sensitive if it is freely available and accessible in the public domain or is furnished under the Right to Information Act, 2005, any rules made thereunder or any other law for the time being in force.</div>
                        <div className='privacy_para py-2'>Due to the existing regulatory environment, we cannot ensure that all of your private communi-cations and other personally identifiable information will never be disclosed in ways not other-wise described in this Privacy Policy. By way of example (without limiting and foregoing), we may be forced to disclose information to the government, law enforcement agencies or third par-ties. Therefore, although we use industry-standard practices to protect your privacy, we do not promise, and you should not expect, that your personally identifiable information or private communications would always remain private. We do however assure you that any disclosure of your personally identifiable information shall be personally intimated to you through an email sent to your provided email address.</div>
                        <div className='privacy_para py-2'>As a matter of policy, we do not sell or rent any personally identifiable information about you to any third party. However, the following describes some of the ways that your personally identifi-able information may be disclosed</div>
                        <ul className='privacy_para pl-8 sm:pl-14'>
                            <li className='list-disc py-2'><span className='font-semibold'>External Service Providers:</span> There may be several services offered by external service pro-viders that help you use our Website. If you choose to use these optional services, and in the course of doing so, disclose information to the external service providers, and/or permit them to collect information about you, then their use of your information is governed by their pri-vacy policy.</li>
                            <li className='list-disc py-2'><span className='font-semibold'>Law and Order:</span>We cooperate with law enforcement inquiries, as well as other third parties to enforce laws, such as intellectual property rights, fraud and other rights. We can (and you authorize us to) disclose any information about you to law enforcement and other govern-ment officials as we, in our sole discretion, believe necessary or appropriate, in connection with an investigation of fraud, intellectual property infringements, or other activity that is il-legal or may expose us or you to legal liability.</li>
                        </ul>
                    </div>

                    <div id='YourProfile' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>11.Accessing, Reviewing And Changing Your Profile.</div>
                        <div className='privacy_para py-2'>Following registration, you can review and change the information you submitted at the stage of registration, except Email ID and mobile number. An option for facilitating such change shall be present on the Website and such change shall be facilitated by the User. If you change any in-formation, we may or may not keep track of your old information. We will not retain in our files information you have requested to remove for certain circumstances, such as to resolve disputes, troubleshoot problems and enforce our terms and conditions. Such prior information shall be completely removed from our databases, including stored ‘back up’ systems. If you believe that any information, we are holding on to you is incorrect or incomplete, or to remove your profile so that others cannot view it, the User needs to remediate, and promptly correct any such incorrect information.</div>
                    </div>

                    <div id='Security' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>12.Security</div>
                        <div className='privacy_para py-2'>We treat data as an asset that must be protected against loss and unauthorized access. We employ many different security techniques to protect such data from unauthorized access by members inside and outside the Company. We follow generally accepted industry standards to protect the Personal Information submitted to us and information that we have accessed.</div>
                        <div className='privacy_para py-2'>However, as effective as encryption technology is, no security system is impenetrable. Our Company cannot guarantee the security of our database, nor can we guarantee that information you provide won’t be intercepted while being transmitted to the Company over the Internet.</div>
                    </div>

                    <div id='Severability' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>13.Severability</div>
                        <div className='privacy_para py-2'>Each paragraph of this Privacy Policy shall be and remain separate from and independent of and severable from all and any other paragraphs herein except where otherwise expressly indicated or indicated by the context of the agreement. The decision or declaration that one or more of the paragraphs are null and void shall not affect the remaining paragraphs of this privacy policy.</div>
                    </div>

                    <div id='Amendment' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>14.Amendment</div>
                        <div className='privacy_para py-2'>Our Privacy Policy may change from time to time. The most current version of the policy will govern our use of your information and will always be on the Website. Any amendments to this Policy shall be deemed as accepted by the User on their continued use of the Website.</div>
                    </div>

                    <div id='DataDownload&DataRemoval' className='py-2 sm:py-5'>
                        <div className='privacy_head2 py-2'>15.Consent Withdrawal, Data Download & Data Removal Requests</div>
                        <div className='privacy_para py-2'>To withdraw your consent, or to request the download or delete your data with us for any or all our services at any time, please email to info@haodapay.com</div>
                    </div>
            </div>
        </div>
      </section>
      <Nextstep />
      <Footer />
    </>
  )
}

export default PrivacyPolicy