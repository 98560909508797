import React from 'react'
import "./blog.css"
import Navbar from '../nav/navbar'
import Footer from '../footer/footer'
import { MdOutlineArrowOutward } from "react-icons/md";
import Nextstep from '../nextStep/nextstep';

// import Logo  from './Light theme logo 1.png'
// import backgroundImage from './Banner 1.jpg'
// import Image1 from './founders.webp'


const Blog = () => {
  return (
    <div>

        <Navbar />

        <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='aboutUs__bannerTitle'>
                    <span className='block text-center'>Blogs</span>
                </div>
            </div>        
        </div>

        <section className='mx-auto py-10 lg:py-20'>

            <div className=''><h1 className='head3 lg:pl-52 2xl:pl-40 lg:block flex justify-center pt-10'>All blog posts</h1></div>

                <div className='flex flex-wrap justify-center my-3 sm:my-5 lg:my-15'> 
                    
                    <a href='/the-ultimate-guide-to-choosing-the-best-payout-service-in-india'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size'  src="\images\Blog 1.jpg" alt="img" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 19, 2024</h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>The Ultimate Guide to Choosing the Best...</h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>In the dynamic landscape of modern business, efficient and reliable payout....</span>
                    </div></a>

                    <a href='/top-10-payout-solutions-in-india-an-in-depth-guide'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size' src="\images\Blog 2.jpg" alt="" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 21, 2024</h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>OurTop 10 Payout Solutions in India...</h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>
                        In the dynamic landscape of modern business, efficient and reliable...</span>
                    </div></a>

                    <a href='/the-growing-trend-indian-businesses-transitioning-to-bulk-payout-solution'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size' src="\images\Blog 3.jpg" alt="" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 21, 2024 </h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>The Growing Trend: Indian Businesses... </h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>In the dynamic landscape of modern business, efficient and reliable payout...</span>
                    </div> </a>

                    <a href='/haodaPay-payout-services-your-go-to-guide-for-fast-and-secure-online-money-transfers'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size'  src="\images\Blog 4.jpg" alt="img" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 23, 2024</h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>Haoda Pay Payout Services...</h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>Fast and secure online money transfers are more important than ever...</span>
                    </div></a>

                    <a href='/the-essential-role-of-payout-service-providers-in-india-digital-economy'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size' src="\images\Blog 5.jpg" alt="" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 22, 2024</h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>The Essential Role of Payout...</h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>
                        In today's digital age, payment processing is an integral...</span>
                    </div> </a>

                    <a href='/selecting-the-optimal-payout-service-in-india-a-comprehensive-guide'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size' src="\images\Blog 6.jpg" alt="" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'> August 23, 2024 </h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>Selecting the Optimal Payout Service... </h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>In today's fast-paced digital world, mobile payments are at the forefront...</span>
                    </div> </a>

                    <a href='/payins-vs-payouts-the-ultimate-guide-to-understanding-both'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size'  src="\images\Blog 7.jpg" alt="img" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 23, 2024</h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>Payins vs Payouts: The Ultimate...</h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>In this guide, we’ll break down the differences between payins and payouts...</span>
                    </div></a>

                    <a href='/mastering-payouts-essential-insights-on-what-they-are-and-who-can-use-them'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size' src="\images\Blog 8.jpg" alt="" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 24, 2024</h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>Mastering Payouts: Essential Insights...</h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>
                        In the realm of e-commerce, user experience (UX) is a critical factor...</span>
                    </div> </a>

                    <a href='/from-transactions-to-trust-how-e-Commerce-platforms-use-payout-services'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size' src="\images\Blog 9.jpg" alt="" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 25, 2024  </h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>From Transactions to Trust... </h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>E-commerce businesses rely on payout services to streamline financial...</span>
                    </div></a>

                    <a href='/features-of-the-best-payout-settlement-api-solution-in-india'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size'  src="\images\Blog 10.jpg" alt="img" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 25, 2024</h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>Features of the Best Payout...</h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>In today’s fast-paced digital economy, businesses in India are increasingly...</span>
                    </div> </a>

                    <a href='/how-payout-services-enhance-employee-satisfaction-and-retention'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size' src="\images\Blog 11.jpg" alt="" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'>August 27, 2024</h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>How Payout Services Enhance...</h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>
                        Employee satisfaction and retention are crucial in today's competitive...</span>
                    </div> </a>

                    <a href='/what-challenges-have-you-faced-with-payout-services-in-your-finance-journey'> <div className='image-card my-5 px-4 sm:px-6 mx-5 py-4 sm:py-6 blogcontent__details cursor-pointer'>
                        <img className='image-size' src="\images\Blog 12.jpg" alt="" />
                        <h4 className='font-semibold paragraph_blog md:pb-3 pt-5 md:pt-8'> August 28, 2024 </h4>
                      <div className='flex md:pb-3 items-center'>
                        <h3 className='head4'>What Challenges Have You Faced... </h3>
                        <div className=''><MdOutlineArrowOutward className='arrow-bar h-5 font-bold flex items-start' /> </div>
                      </div>
                        <span className='paragraph text-gray-600'>Dealing with a variety of operational difficulties is frequently necessary ...</span>
                    </div></a>
            </div>
        </section>

       <Nextstep />

        <Footer />

    </div>
  )
}

export default Blog