import React from 'react'
import "./clubassociation.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'

const Clubassociation = () => {
  return (
    <div>
        <Navbar />

        <div className='clubAssociation__bannerBg haodapay__certificateBannerTop mb-20 h-fit'>

              <div className='clubAssociation__BgVideo'>
                <video loop autoPlay muted playsinline className='w-full h-full'>
                  <source src='\images\Web 800_1.mp4' type='video/mp4' className='w-full h-full' />
                </video>
              </div>          

              <div className='clubAssociation__bannerContent container mx-auto 2xl:px-20 lg:flex justify-between items-center flex-wrap lg:flex-nowrap py-14 px-5 xl:px-10'>
                  <div className='lg:w-1/2 lg:pl-5 2xl:pl-0'>
                      <span className='block clubAssociation__BannerTitle text-center lg:text-left'>Explore Effective Ways to Manage Club and Society Finances with Haoda Pay.</span>
                      <span className='block clubAssociation__BannerPara mt-5 sm:mt-8 text-center lg:text-left'>Learn why choosing the right payment method matters for success. Follow along as we explore and offer insights using HaodaPay.</span>
                  </div>
                  <div className='lg:w-1/2 flex justify-center lg:justify-end items-center mt-10 lg:mt-0 lg:pl-5 2xl:pl-0'>
                    <div className='clubAssociation__img grid place-content-end'>
                        <img src='/images/housing__houseImg.png' alt='img' />
                    </div>
                  </div>
              </div>
        </div>

        <div className='container mx-auto xl:px-40 flex justify-center items-center flex-wrap-reverse lg:flex-nowrap mt-10 md:mt-0 mb-20 px-5 lg:px-0'>
            <div className='clubPaymentMethod__img lg:w-1/2 grid place-content-center lg:pr-20 mt-10'>
                <img src='\images\payment__method.png' alt='img' />
            </div>
            <div className='lg:w-1/2 lg:pr-20 xl:pr-0 2xl:pr-24'>
                <span className='clubPaymentMethod__contentTitle block text-center lg:text-left'>Why Payment Method Matters</span>
                <span className='clubPaymentMethod__contentPara block text-center lg:text-left mt-7'>The payment method you choose can significantly impact the financial health and operational efficiency of your club or society. From collecting membership dues and event fees to managing expenses and donations, having a reliable and convenient payment solution is essential. The right payment method can streamline processes, improve transparency, and enhance member satisfaction.</span>
            </div>
        </div> 

        {/* Club society section */}

        <div className='container mx-auto 2xl:px-20 my-20 px-5 lg:px-5 xl:px-0'>
            <span className='clubSociety__title block text-center'>Essential Payment Options for Clubs and Societies</span>
            <div className='flex justify-center items-center mt-12'>
                <div className='grid md:grid-cols-2 gap-7 lg:gap-14 xl:w-3/4'>
                    <div className='flex flex-col justify-center items-center'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                              <g clip-path="url(#clip0_2265_6747)">
                                <path d="M36.2021 32.8125C40.1644 32.8125 43.4777 29.9894 44.2402 26.25H28.1641C28.9266 29.9894 32.2399 32.8125 36.2021 32.8125Z" fill="#F4B936"/>
                                <path d="M36.2031 6.5625C26.0889 6.5625 18.1562 14.8086 18.1562 24.6094C18.1562 29.379 20.0981 33.9533 23.53 37.3691L21.7115 40.1088C21.3766 40.6119 21.3462 41.2592 21.633 41.7911C21.9181 42.3246 22.474 42.6562 23.0781 42.6562H36.2031C46.1542 42.6562 54.25 34.5604 54.25 24.6094C54.25 14.6583 46.1542 6.5625 36.2031 6.5625ZM39.4844 16.4062H42.7656C43.6725 16.4062 44.4062 17.14 44.4062 18.0469C44.4062 18.9537 43.6725 19.6875 42.7656 19.6875H39.4844C38.5775 19.6875 37.8438 18.9537 37.8438 18.0469C37.8438 17.14 38.5775 16.4062 39.4844 16.4062ZM29.6406 16.4062H32.9219C33.8287 16.4062 34.5625 17.14 34.5625 18.0469C34.5625 18.9537 33.8287 19.6875 32.9219 19.6875H29.6406C28.7338 19.6875 28 18.9537 28 18.0469C28 17.14 28.7338 16.4062 29.6406 16.4062ZM36.2031 36.0938C29.8713 36.0938 24.7188 30.9412 24.7188 24.6094C24.7188 23.7025 25.4525 22.9688 26.3594 22.9688H46.0469C46.9537 22.9688 47.6875 23.7025 47.6875 24.6094C47.6875 30.9412 42.535 36.0938 36.2031 36.0938Z" fill="#F4B936"/>
                                <path d="M23.0781 45.9375C21.258 45.9375 19.5966 44.941 18.7394 43.3372C17.8806 41.7446 17.9704 39.8076 18.9797 38.2903L19.4027 37.6543C16.466 33.91 14.875 29.3662 14.875 24.6094C14.875 15.3841 21.0476 6.33391 31.1715 3.89987C30.6993 1.6753 28.7227 0 26.3594 0H6.67188C3.95784 0 1.75 2.20784 1.75 4.92188V51.0781C1.75 53.7922 3.95784 56 6.67188 56H26.3594C29.0734 56 31.2812 53.7922 31.2812 51.0781V45.9375H23.0781ZM13.2344 8.20312C13.2344 7.2963 13.9682 6.5625 14.875 6.5625H18.1562C19.0631 6.5625 19.7969 7.2963 19.7969 8.20312C19.7969 9.10995 19.0631 9.84375 18.1562 9.84375H14.875C13.9682 9.84375 13.2344 9.10995 13.2344 8.20312ZM16.5156 49.2188C15.6096 49.2188 14.875 48.4842 14.875 47.5781C14.875 46.6718 15.6096 45.9375 16.5156 45.9375C17.4217 45.9375 18.1562 46.6718 18.1562 47.5781C18.1562 48.4842 17.4217 49.2188 16.5156 49.2188Z" fill="#392675"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_2265_6747">
                                  <rect width="56" height="56" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                        </span>
                        <span className='block clubSociety__contentTitle my-4'>Online Payment Platforms</span>
                        <span className='block clubSociety__contentPara text-center md:text-left'>Payment service providers offers online platforms for clubs and societies to collect payments electronically via credit/debit cards, bank transfers, and digital wallets. These platforms ensure secure processing, easy website and app integration, and robust transaction tracking.</span>
                    </div>

                    <div className='flex flex-col justify-center items-center'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                              <g clip-path="url(#clip0_2265_6756)">
                                <path d="M1.73337 8.64008C1.59381 11.3828 2.70484 14.3046 4.90908 16.5104L7.22903 14.1904C5.96356 12.925 5.18787 11.359 4.98881 9.83697C7.64881 9.80077 9.80131 7.64816 9.83719 4.98816C11.361 5.18678 12.924 5.96367 14.1905 7.22903L16.5105 4.90908C14.3047 2.70473 11.3837 1.59381 8.64019 1.73337C7.73664 0.681297 6.41408 0 4.92188 0C2.20784 0 0 2.20784 0 4.92188C0 6.41408 0.681297 7.73664 1.73337 8.64008Z" fill="#F4B936"/>
                                <path d="M4.92188 56.0005C6.39166 56.0005 7.69759 55.34 8.60027 54.3155C8.75623 54.3239 8.90827 54.3583 9.06511 54.3583C11.6798 54.3583 14.4212 53.1792 16.5104 51.0915L14.1904 48.7716C12.9239 50.0369 11.361 50.813 9.83697 51.0118C9.80077 48.352 7.64859 46.1998 4.98881 46.1636C5.18787 44.6416 5.96356 43.0757 7.22903 41.8102L4.90908 39.4902C2.70484 41.696 1.59381 44.6177 1.73337 47.3605C0.681297 48.2639 0 49.5864 0 51.0786C0 53.7927 2.20784 56.0005 4.92188 56.0005Z" fill="#F4B936"/>
                                <path d="M51.0807 0C49.5885 0 48.2657 0.681297 47.3625 1.73337C44.6167 1.59359 41.6967 2.70452 39.4922 4.90908L41.8121 7.22903C43.0776 5.96356 44.6408 5.18645 46.1655 4.98794C46.2014 7.64794 48.3539 9.80066 51.0139 9.83686C50.8148 11.3589 50.0391 12.9248 48.7736 14.1903L51.0936 16.5103C53.2978 14.3045 54.4089 11.3828 54.2693 8.63997C55.3213 7.73664 56.0026 6.41408 56.0026 4.92187C56.0026 2.20784 53.7947 0 51.0807 0Z" fill="#F4B936"/>
                                <path d="M54.2692 47.3605C54.4088 44.6178 53.2977 41.696 51.0935 39.4902L48.7735 41.8102C50.039 43.0757 50.8147 44.6416 51.0138 46.1636C48.354 46.1998 46.2018 48.3522 46.1656 51.012C44.6407 50.8134 43.0776 50.037 41.8121 48.7716L39.4922 51.0915C41.5815 53.1792 44.3227 54.3583 46.9375 54.3583C47.0943 54.3583 47.2462 54.3239 47.4023 54.3155C48.305 55.3401 49.6109 56.0006 51.0807 56.0006C53.7947 56.0006 56.0026 53.7928 56.0026 51.0787C56.0026 49.5865 55.3213 48.264 54.2692 47.3605Z" fill="#F4B936"/>
                                <path d="M6.64844 26.3594H19.8269C20.1023 16.3228 22.8959 6.5625 28.0028 6.5625C16.7966 6.5625 7.49161 15.3654 6.64844 26.3594Z" fill="#392675"/>
                                <path d="M28.0017 9.84375C26.4138 9.84375 23.4324 15.7664 23.1367 26.3594H32.8667C32.5711 15.7664 29.5896 9.84375 28.0017 9.84375Z" fill="#392675"/>
                                <path d="M28 6.5625C33.1069 6.5625 35.9005 16.3228 36.1759 26.3594H49.3544C48.5112 15.3654 39.2062 6.5625 28 6.5625Z" fill="#392675"/>
                                <path d="M49.3544 29.6406H36.1759C35.9005 39.6772 33.1069 49.4375 28 49.4375C39.2062 49.4375 48.5112 40.6346 49.3544 29.6406Z" fill="#392675"/>
                                <path d="M6.64844 29.6406C7.49161 40.6346 16.7966 49.4375 28.0028 49.4375C22.8959 49.4375 20.1023 39.6772 19.8269 29.6406H6.64844Z" fill="#392675"/>
                                <path d="M28.0017 46.1562C29.5896 46.1562 32.5711 40.2336 32.8667 29.6406H23.1367C23.4324 40.2336 26.4138 46.1562 28.0017 46.1562Z" fill="#392675"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_2265_6756">
                                  <rect width="56" height="56" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                        </span>
                        <span className='block clubSociety__contentTitle my-4'>Mobile Payment Solutions</span>
                        <span className='block clubSociety__contentPara text-center md:text-left'>With smartphones on the rise, mobile payments are now popular among clubs and societies. Providers offer mobile apps that enable payments via QR codes, NFC, or mobile wallets, making it easy to pay at events and fundraisers.</span>
                    </div>

                    <div className='flex flex-col justify-center items-center'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                              <g clip-path="url(#clip0_2265_6747)">
                                <path d="M36.2021 32.8125C40.1644 32.8125 43.4777 29.9894 44.2402 26.25H28.1641C28.9266 29.9894 32.2399 32.8125 36.2021 32.8125Z" fill="#F4B936"/>
                                <path d="M36.2031 6.5625C26.0889 6.5625 18.1562 14.8086 18.1562 24.6094C18.1562 29.379 20.0981 33.9533 23.53 37.3691L21.7115 40.1088C21.3766 40.6119 21.3462 41.2592 21.633 41.7911C21.9181 42.3246 22.474 42.6562 23.0781 42.6562H36.2031C46.1542 42.6562 54.25 34.5604 54.25 24.6094C54.25 14.6583 46.1542 6.5625 36.2031 6.5625ZM39.4844 16.4062H42.7656C43.6725 16.4062 44.4062 17.14 44.4062 18.0469C44.4062 18.9537 43.6725 19.6875 42.7656 19.6875H39.4844C38.5775 19.6875 37.8438 18.9537 37.8438 18.0469C37.8438 17.14 38.5775 16.4062 39.4844 16.4062ZM29.6406 16.4062H32.9219C33.8287 16.4062 34.5625 17.14 34.5625 18.0469C34.5625 18.9537 33.8287 19.6875 32.9219 19.6875H29.6406C28.7338 19.6875 28 18.9537 28 18.0469C28 17.14 28.7338 16.4062 29.6406 16.4062ZM36.2031 36.0938C29.8713 36.0938 24.7188 30.9412 24.7188 24.6094C24.7188 23.7025 25.4525 22.9688 26.3594 22.9688H46.0469C46.9537 22.9688 47.6875 23.7025 47.6875 24.6094C47.6875 30.9412 42.535 36.0938 36.2031 36.0938Z" fill="#F4B936"/>
                                <path d="M23.0781 45.9375C21.258 45.9375 19.5966 44.941 18.7394 43.3372C17.8806 41.7446 17.9704 39.8076 18.9797 38.2903L19.4027 37.6543C16.466 33.91 14.875 29.3662 14.875 24.6094C14.875 15.3841 21.0476 6.33391 31.1715 3.89987C30.6993 1.6753 28.7227 0 26.3594 0H6.67188C3.95784 0 1.75 2.20784 1.75 4.92188V51.0781C1.75 53.7922 3.95784 56 6.67188 56H26.3594C29.0734 56 31.2812 53.7922 31.2812 51.0781V45.9375H23.0781ZM13.2344 8.20312C13.2344 7.2963 13.9682 6.5625 14.875 6.5625H18.1562C19.0631 6.5625 19.7969 7.2963 19.7969 8.20312C19.7969 9.10995 19.0631 9.84375 18.1562 9.84375H14.875C13.9682 9.84375 13.2344 9.10995 13.2344 8.20312ZM16.5156 49.2188C15.6096 49.2188 14.875 48.4842 14.875 47.5781C14.875 46.6718 15.6096 45.9375 16.5156 45.9375C17.4217 45.9375 18.1562 46.6718 18.1562 47.5781C18.1562 48.4842 17.4217 49.2188 16.5156 49.2188Z" fill="#392675"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_2265_6747">
                                  <rect width="56" height="56" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>
                        </span>
                        <span className='block clubSociety__contentTitle my-4'>Recurring Payment Plans</span>
                        <span className='block clubSociety__contentPara text-center md:text-left'>Payment service providers offer recurring payment plans for clubs and societies, automating fee collection, reducing admin work, and ensuring steady revenue through easy billing schedules and subscription management.</span>
                    </div>

                    <div className='flex flex-col justify-center items-center'>
                        <span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                              <g clip-path="url(#clip0_2265_6756)">
                                <path d="M1.73337 8.64008C1.59381 11.3828 2.70484 14.3046 4.90908 16.5104L7.22903 14.1904C5.96356 12.925 5.18787 11.359 4.98881 9.83697C7.64881 9.80077 9.80131 7.64816 9.83719 4.98816C11.361 5.18678 12.924 5.96367 14.1905 7.22903L16.5105 4.90908C14.3047 2.70473 11.3837 1.59381 8.64019 1.73337C7.73664 0.681297 6.41408 0 4.92188 0C2.20784 0 0 2.20784 0 4.92188C0 6.41408 0.681297 7.73664 1.73337 8.64008Z" fill="#F4B936"/>
                                <path d="M4.92188 56.0005C6.39166 56.0005 7.69759 55.34 8.60027 54.3155C8.75623 54.3239 8.90827 54.3583 9.06511 54.3583C11.6798 54.3583 14.4212 53.1792 16.5104 51.0915L14.1904 48.7716C12.9239 50.0369 11.361 50.813 9.83697 51.0118C9.80077 48.352 7.64859 46.1998 4.98881 46.1636C5.18787 44.6416 5.96356 43.0757 7.22903 41.8102L4.90908 39.4902C2.70484 41.696 1.59381 44.6177 1.73337 47.3605C0.681297 48.2639 0 49.5864 0 51.0786C0 53.7927 2.20784 56.0005 4.92188 56.0005Z" fill="#F4B936"/>
                                <path d="M51.0807 0C49.5885 0 48.2657 0.681297 47.3625 1.73337C44.6167 1.59359 41.6967 2.70452 39.4922 4.90908L41.8121 7.22903C43.0776 5.96356 44.6408 5.18645 46.1655 4.98794C46.2014 7.64794 48.3539 9.80066 51.0139 9.83686C50.8148 11.3589 50.0391 12.9248 48.7736 14.1903L51.0936 16.5103C53.2978 14.3045 54.4089 11.3828 54.2693 8.63997C55.3213 7.73664 56.0026 6.41408 56.0026 4.92187C56.0026 2.20784 53.7947 0 51.0807 0Z" fill="#F4B936"/>
                                <path d="M54.2692 47.3605C54.4088 44.6178 53.2977 41.696 51.0935 39.4902L48.7735 41.8102C50.039 43.0757 50.8147 44.6416 51.0138 46.1636C48.354 46.1998 46.2018 48.3522 46.1656 51.012C44.6407 50.8134 43.0776 50.037 41.8121 48.7716L39.4922 51.0915C41.5815 53.1792 44.3227 54.3583 46.9375 54.3583C47.0943 54.3583 47.2462 54.3239 47.4023 54.3155C48.305 55.3401 49.6109 56.0006 51.0807 56.0006C53.7947 56.0006 56.0026 53.7928 56.0026 51.0787C56.0026 49.5865 55.3213 48.264 54.2692 47.3605Z" fill="#F4B936"/>
                                <path d="M6.64844 26.3594H19.8269C20.1023 16.3228 22.8959 6.5625 28.0028 6.5625C16.7966 6.5625 7.49161 15.3654 6.64844 26.3594Z" fill="#392675"/>
                                <path d="M28.0017 9.84375C26.4138 9.84375 23.4324 15.7664 23.1367 26.3594H32.8667C32.5711 15.7664 29.5896 9.84375 28.0017 9.84375Z" fill="#392675"/>
                                <path d="M28 6.5625C33.1069 6.5625 35.9005 16.3228 36.1759 26.3594H49.3544C48.5112 15.3654 39.2062 6.5625 28 6.5625Z" fill="#392675"/>
                                <path d="M49.3544 29.6406H36.1759C35.9005 39.6772 33.1069 49.4375 28 49.4375C39.2062 49.4375 48.5112 40.6346 49.3544 29.6406Z" fill="#392675"/>
                                <path d="M6.64844 29.6406C7.49161 40.6346 16.7966 49.4375 28.0028 49.4375C22.8959 49.4375 20.1023 39.6772 19.8269 29.6406H6.64844Z" fill="#392675"/>
                                <path d="M28.0017 46.1562C29.5896 46.1562 32.5711 40.2336 32.8667 29.6406H23.1367C23.4324 40.2336 26.4138 46.1562 28.0017 46.1562Z" fill="#392675"/>
                              </g>
                              <defs>
                                <clipPath id="clip0_2265_6756">
                                  <rect width="56" height="56" fill="white"/>
                                </clipPath>
                              </defs>
                            </svg>                        
                        </span>
                        <span className='block clubSociety__contentTitle my-4'>Donations and Fundraising Tools</span>
                        <span className='block clubSociety__contentPara text-center md:text-left'>Payment service providers offer tools for clubs and societies to facilitate donations and fundraising campaigns, allowing online donation collection and real-time tracking of progress.</span>
                    </div>
                </div>
            </div>
        </div>

        {/* clubassociation payments method */}

        <div className='container mx-auto 2xl:px-20 my-20 px-5 md:px-0'>
            <div className='flex justify-center items-center lg:px-20'>
                <span className='clubchoosingPayment__heading text-center lg:w-4/5'>Considerations When Choosing a Payment Method using <span className='clubchoosingPayment__headingHaoda'>Haoda</span> <span className='clubchoosingPayment__headingPay'>Pay</span></span>
            </div>
            <div className='flex gap-3 justify-center mt-12 flex-wrap xl:flex-nowrap'>
                <div className='clubchoosingPayment__box pt-5 px-5 pb-12'>
                    <img src='\images\Article_Image.png' alt='img' />
                    <span className='block clubchoosingPayment__boxTitle my-5 text-center md:text-left'>Convenience:</span>
                    <span className='block clubchoosingPayment__boxPara text-center md:text-left'>Opt for a payment method that offers convenience for both your organization and its members. Ensure it's user-friendly, accessible, and provides flexible payment options.</span>
                </div>
                <div className='clubchoosingPayment__box1 pt-5 px-5 pb-12'>
                    <img src='\images\Article_Image.png' alt='img' />
                    <span className='block clubchoosingPayment__boxTitle my-5 text-center md:text-left'>Security:</span>
                    <span className='block clubchoosingPayment__boxPara text-center md:text-left'>Prioritize security by selecting a payment method with robust security features. Look for compliance with industry standards and regulations to safeguard against fraud and data breaches.</span>
                </div>
                <div className='clubchoosingPayment__box2 pt-5 px-5 pb-12'>
                    <img src='\images\Article_Image.png' alt='img' />
                    <span className='block clubchoosingPayment__boxTitle my-5 text-center md:text-left'>Cost:</span>
                    <span className='block clubchoosingPayment__boxPara text-center md:text-left'>Evaluate the costs associated with each payment method, including transaction fees, setup fees, and maintenance costs. Choose a solution with transparent pricing that aligns with your budgetary constraints.</span>
                </div>
                <div className='clubchoosingPayment__box3 pt-5 px-5 pb-12'>
                    <img src='\images\Article_Image.png' alt='img' />
                    <span className='block clubchoosingPayment__boxTitle my-5 text-center md:text-left'>Integration:</span>
                    <span className='block clubchoosingPayment__boxPara text-center md:text-left'>If your club or society uses other management software or tools, consider the compatibility and integration capabilities of the payment method. Seamless integration can streamline workflows and enhance efficiency.</span>
                </div>
            </div>
        </div>

        <Nextstep />

        <Footer />


    </div>
  )
}

export default Clubassociation