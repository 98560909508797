import React from 'react'
import "./certificate.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'

const Certificate = () => {
  return (
    <div>
        <Navbar />
        
        <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='aboutUs__bannerTitle'>
                    <span className='block text-center'>HaodaPay Certification</span>
                </div>
            </div>        
        </div>

        <div className='certificate__container container mx-auto px-20 my-20'>
            <div className='flex items-center justify-center flex-wrap gap-y-12 gap-x-5'>
                <div className='certificate__content p-5'>
                    <img src='\images\pci_certificate.jpg' alt='img' />
                </div>
                <div className='certificate__content p-5'>
                    <img src='\images\ISO 8385.jpg' alt='img' />
                </div>
                <div className='certificate__content p-5'>
                    <img src='\images\ISO 20022-1.jpg' alt='img' />
                </div>
                <div className='certificate__content p-5'>
                    <img src='\images\PCI S3.jpg' alt='img' />
                </div>
                <div className='certificate__content p-5'>
                    <img src='\images\PCI-DSS.jpg' alt='img' />
                </div>
            </div>
        </div>

        <Nextstep />

        <Footer />

    </div>
  )
}

export default Certificate