import axios  from "axios"
import { AppKey } from "../constant/AppKey"

let bower = {};
let ipinfo = {};
let cloudflareipinfo = {};

export const getIPINFO = async () => {
  return await ipinfo?.data?.ip;
};

export const getCloudflareIPINFOData = async () => {
  cloudflareipinfo = await AxioscloudflareIPINFO();
  return cloudflareipinfo;
};

export const getBrowser = () => {
  return bower.getParser(window.navigator.userAgent)?.parsedResult;
};

export const getCloudflareIPINFO = async () => {
  cloudflareipinfo = await AxioscloudflareIPINFO();
  return cloudflareipinfo?.ip;
};

export const gettelemetry = async() => {
  let ipaddress = await getCloudflareIPINFO();
  let ipinfo = await getIPINFO();
  
  return {
      "ip": ipinfo === null || ipinfo === undefined ? ipaddress : ipinfo,
      "city": "Mumbai",
      "region": "Maharashtra",
      "country": "India",
      "latitude": 19.0760,
      "longitude": 72.8777,
      "browser": "Firefox",
      "engine": "Blink",
      "os": "Ubuntu 20.04",
      "platform": "Desktop"
    }
}


export const AxioscloudflareIPINFO = async () => {
  return await axios
    .get(AppKey.CLOUDFLAREIPINFOURL)
    .then((res) => {
      const data = res?.data?.trim().split('\n').reduce(function(obj, pair) {
        pair = pair.split('=');
        return obj[pair[0]] = pair[1], obj;
      }, {});
      return data;
    })
    .catch(() => {});
};


export const AxiosPost = async( apiname, payload ) => {
  const headers = {
    'clientid': AppKey.clientId,
    'clientsecret': AppKey.clientSecret,
    'Content-Type': 'application/json',
  }

  return axios.post(AppKey.APIURL, apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  })

}

export const AxiosFilePost = async (
  apiname,
  payload,
) => {
  const headers = {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data",
  };
  return await axios.post(apiname, payload, {
    headers,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
})
};