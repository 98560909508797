import React, { useState } from 'react'
import './eventpage.css'
import { FaArrowRight } from "react-icons/fa";
import Navbar from '../nav/navbar';
import Nextstep from '../nextStep/nextstep';
import Footer from '../footer/footer';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


function Gff() {

  const [sliderPopup, setSliderPopup] = useState(false)
  const [selectedSlide, setSelectedSlide] = useState(0); // Track the clicked image index

  const openSliderPopup = (index) => {
    setSelectedSlide(index); // Set the selected slide index
    setSliderPopup(true); // Open the popup
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 2000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      }
    ]
  };

  const sliders = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,  
    initialSlide: selectedSlide 
  };

  return (
    <>
      
      <Navbar />

      <section id='all_events_page' className='haodapay__certificateBannerTop'>
        <div className='py-10'>
          <div className='heading1_event text-center pb-5'>Global Fintech Fest 2024</div>
          <div className='flex justify-center'>
          <ul className='paragraph_event pl-3 sm:pl-0 md:flex justify-center'>
            <li className='mx-5'>Date: 28th to 30th August 2024</li>
            <li className='mx-5'>Location:  Jio World Convention Centre, Mumbai, India            </li>
          </ul>
          </div>


          <div className="slider-container my-10 md:my-20">
          <Slider {...settings}>
        {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
          <div className="slider__eventImg px-3" onClick={() => openSliderPopup(index)} key={index}>
            <img src={`/images/gffImg${index + 1}.jpg`} alt="img" />
          </div>
        ))}
      </Slider>
          </div>

          { sliderPopup && (

         <div className='sliderPopup__container p-5 md:p-10'>
          
          <div className='w-full h-full flex justify-center items-center gap-x-5'>
<div className='w-full md:w-4/5'>
         <div className='flex justify-end cursor-pointer mb-5 md:mb-10'>
            <div onClick={() => setSliderPopup(false)}>
              <span className='text-white	'>X</span> 
            </div>
          </div>
          <div >
          <Slider {...sliders}>
                {[0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                  <div key={index} className="sliderPopup__containerImg ">
                    <img src={`/images/gffImg${index + 1}.jpg`} alt="img" />
                  </div>
                ))}
              </Slider>
          </div>
</div>
          </div>
          </div> )}
       
        <div className='flex py-10 justify-center'>
           <div className='md:w-2/3 sm:w-3/4 sm:px-0 px-5 lg:w-3/5 xl:w-1/2'>
              <div className='heading2_event py-2 md:py-2.5'>
Haoda pay is excited to announce our Contribution in the Global Financial Fest. Join us and see what's next in financial tech! 
</div>
              <div className='paragraph_event py-2 md:py-2.5'>JWe’re thrilled to introduce two game-changing solutions at this year’s Global Fintech Awards : <span className='inline-block font-semibold'>VAN and BBPS! </span></div>

              <div className='paragraph_event py-2 md:py-2.5'>VAN (Virtual Account Numbers) and BBPS (Bharat Bill Payment System) are set to revolutionize how businesses handle transactions and bill payments. 
              </div>

              <div className='paragraph_event py-2 md:py-2.5'> <span className='inline-block font-bold'>VAN</span> : Experience seamless and secure virtual account management, designed to streamline transactions and improve financial operations.
              </div>

              <div className='paragraph_event py-2 md:py-2.5'><span className='inline-block font-bold'>BBPS :</span>  Discover how our integration with the Bharat Bill Payment System can simplify bill payments and enhance accessibility for users.

              </div>
              <div className='paragraph_event py-2 md:py-2.5'>Be a part of the future of fintech and witness these transformative solutions in action. We look forward to connecting with you at the Global Fintech Fest.

</div>
          </div>
        </div> 

        <div className='eventPage__video container mx-auto px-5 lg:px-40 my-10 md:my-20'>
        <iframe width="100%" height="" src="https://www.youtube.com/embed/2GHYqC8GtTY?si=maIz4oYjPkT5JCex" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>                          

        



        <div className=''>
            <div className='all_events text-center'>All Events</div>

            <div className='container mx-auto flex justify-center flex-wrap lg:flex-nowrap gap-12 my-10 lg:my-20 px-5'>

               
                  
<div className='md:w-3/5 xl:w-2/5 2xl:w-1/3 p-5 md:p-10 hover:shadow-lg rounded-lg sm:rounded-2xl border shadow-sm cursor-pointer'><a href='/event/money-expo'>
 <div>
  <img className='event_image rounded-md lg:rounded-2xl' src="\images\moneyExpoImg4.jpg" alt='img' />
  <div className='paragraph py-1 md:py-3 lg:py-4'>Date: 17th to 18th August 2024</div>
  <div className='paragraph pb-1 md:pb-3 lg:pb-4'>Location: io World Convention Centre, Mumbai, India</div>
  <div className='body_heading pb-1 md:pb-3 lg:pb-4'>Money Expo India as Haoda Pay</div>
  <div className='paragraph pb-1 md:pb-3 lg:pb-4'>Discover the Future of Payments with Haoda Pay at Money Expo India!</div>
  <div className='body_heading2 flex items-center'>Read More<FaArrowRight className='ml-2 text-sm'/></div></div></a>
</div>

<div className='md:w-3/5 xl:w-2/5 2xl:w-1/3 hover:shadow-lg p-5 md:p-10 rounded-lg sm:rounded-xl md:rounded-2xl lg:rounded-3xl border shadow-sm'>
<a href='/event/global-fintech-fest'> <div>
 <img className='event_image rounded-md md:rounded-2xl' src="\images\gffImg1.jpg" alt='img' />
 <div className='paragraph py-1 md:py-3 lg:py-4'>Date: 28th to 30th August 2024 </div>
 <div className='paragraph pb-1 md:pb-3 lg:pb-4'>Location: Jio World Convention Centre, Mumbai</div>
 <div className='body_heading pb-1 md:pb-3 lg:pb-4'>
Haoda pay is excited to announce our Contribution in the Global Financial Fest
</div>
 <div className='body_heading2 flex items-center'><span>Read More</span><FaArrowRight className='ml-2 text-sm' /></div></div></a>
</div> 
</div>
        
        </div>
        </div>
      </section>

      <Nextstep />
      <Footer />
    </>
  )
}

export default Gff