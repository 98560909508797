import React from 'react'
import "./contact.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'

const Contact = () => {
  return (
    <div>

        <Navbar />

        <div className='container mx-auto px-5 sm:px-10  md:px-20 lg:px-40 flex flex-wrap xl:flex-nowrap justify-between gap-x-20 items-center haodapay__certificateBannerTop mb-20 py-20'>
            <div className='w-full xl:w-1/2'>
                <span className='contactMain__title block'>Drop us a message and say hey!</span>
                <span className='contactMain__Para block mt-8'>You can use the form below to reach out to us. You may also contact us directly on the coordinates shared here. Haoda respects your privacy. We will not use or share your data for any reason other than to provide you with information about us. Your requested information will be shared shortly.Thank you.</span>
                <form className='mt-20 flex flex-col gap-5'>
                    <input type='text' placeholder='Name' className='px-4 block contactForm__input'/>
                    <input type='text' placeholder='Email address' className='px-4 block contactForm__input' />
                    <input type='text' placeholder='Phone number' className='px-4 block contactForm__input' />
                    <input type='text' placeholder='How did you hear about Haoda Pay?' className='px-4 block contactForm__input' />
                    <div className='mt-3 w-full grid place-content-center'>
                        <button className='contactForm__btn'>Send</button>
                    </div>
                </form>
            </div>
            <div className='w-full xl:w-1/2 mt-10 xl:mt-0'>
                <div>
                    <img src='\images\contact__img.png' alt='img' />
                </div>
            </div>
        </div>

        {/* MAP section */}

        <div class="map">
            <div class="map__iframe">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3886.3197297406396!2d80.24205028485227!3d13.078911754362148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a52667658f0e92f%3A0x52bcd30632d202c5!2sThyagaraja%20Complex%2C%20Kilpauk%2C%20Chennai%2C%20Tamil%20Nadu%20600010!5e0!3m2!1sen!2sin!4v1705061912978!5m2!1sen!2sin" style={{border: "0"}}  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        
        <Nextstep />

        <Footer />

    </div>
  )
}

export default Contact