import React, { useState } from 'react';
import './demo.css';

const Demo = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="square"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className={`circle ${isHovered ? 'hovered' : ''}`}></div>
    </div>
  );};

export default Demo;
