import React, { useState } from 'react'
import "./careerPage.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'
import axios from "axios"
import { AxiosFilePost } from '../utilities/axiospost'
import { AppKey } from '../constant/AppKey'
import { ToastError, ToastSuccess } from '../utilities/toast'

const PrMarketting = () => {

    const [careerFile, setCareerFile] = useState()
    const [toggle, setToggle] = useState(1)
    const [careerFileError, setCareerFileError] = useState()
    const [loader, setLoader] = useState(false)

    const handleChangeTab = (item) => {
        setToggle(item)
    }

    const [formDetail, setFormDetail] = useState({
        name: "",
        email: "",
        phonenumber: "",
        notice: "",
        currentsalary: "",
        expectedsalary: "",
        jobtitle: ""
    })

    const [formError, setFormError] = useState({
        name: "",
        email: "",
        phonenumber: "",
        notice: "",
        currentsalary: "",
        expectedsalary: "",
        jobtitle: ""
    })

    const handleInput = (e) => {
        const { name, value } = e.target
        setFormDetail({ ...formDetail, [name] : value})
        setFormError({ ...formError, [name] : ""})
    }

    const handleValidation = () => {
        let valid = true
        const newError = {}

        if(!formDetail.name){
            valid = false
            newError.name = "Name is required"
        }else if(formDetail.name < 3){
            valid = false
            newError.name = "Minmum 3 letters required"
        }else if(!/^[A-Za-z ]*$/.test(formDetail.name)){
            valid = false
            newError.name = "Only letters are allowed"
        }

        if(!formDetail.email){
            valid = false
            newError.email = "Email id is required"
        }else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formDetail.email)){
            valid = false
            newError.email = "Invalid email address"
        }

        if(!formDetail.phonenumber){
            valid = false
            newError.phonenumber = "Phone number is required"
        }else if(!/^\d{10}$/.test(formDetail.phonenumber)){
            valid = false
            newError.phonenumber = "Invalid phone number"
        }else if(!/^[6789]\d{9}$/.test(formDetail.phonenumber)){
            valid = false
            newError.phonenumber = "Mobile number starting with 6, 7, 8, or 9"
        }

        if(!formDetail.jobtitle){
            valid = false
            newError.jobtitle = "Job title is required"
        }else if(!/^[A-Za-z ]*$/.test(formDetail.jobtitle)){
            valid = false
            newError.jobtitle = "Only letters are allowed"
        }

        if(!formDetail.notice){
            valid = false
            newError.notice = "Notice period is required"
        }else if(!/^[0-9]*$/.test(formDetail.notice)){
            valid = false
            newError.notice = "Only numbers are allowed"
        }

        if(!formDetail.currentsalary){
            valid = false
            newError.currentsalary = "Currentsalary is required"
        }

        if(!formDetail.expectedsalary){
            valid = false
            newError.expectedsalary = "Expected salary is required"
        }

        setFormError(newError)

        return valid;
    
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 4 * 1024 * 1024) {
                setCareerFileError("File size exceeds 4MB");
                setCareerFile(null);
            } else if (file.type !== "application/pdf") {
                setCareerFileError("Invalid file type. Only PDF files are allowed.");
                setCareerFile(null);
            } else {
                setCareerFileError("");
                setCareerFile(file);
            }
        }
    };
    

    const handleForm = async (e) => {
        e.preventDefault();
    
        if (!handleValidation()) {
          return;
        }
    
        const data = new FormData();
        data.append('email', formDetail.email);
        data.append('attachement', careerFile);
        data.append('name', formDetail.name);
        data.append('jobtitle', formDetail.jobtitle);
        data.append('mobile', formDetail.phonenumber);
        data.append('notice', formDetail.notice);
        data.append('currentsalary', formDetail.currentsalary);
        data.append('exceptedsalary', formDetail.expectedsalary);

        setLoader(true)
    
        await AxiosFilePost(AppKey.CAREERAPI, data)
        .then((res) => {
            if(res.data.statuscode === 200){
                ToastSuccess("Job Apply Successfully!")
                setFormDetail({
                    name: "",
                    email: "",
                    phonenumber: "",
                    notice: "",
                    currentsalary: "",
                    expectedsalary: "",
                    jobtitle: ""
                            
                })
                setCareerFile(null)
            }
        })
        .catch((error) => {
            ToastError("Something went wrong");
        })
        .finally(() => {
            setLoader(false)
        })
    };
    
    

  return (
    <div>

        <Navbar />

        <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='backend__bannerTitle'>
                    <span className='block text-center'>PR Marketing and Profile Maintenance Analyst</span>
                </div>
            </div>        
        </div>

        <div className='px-5 sm:px-10 md:px-20 xl:px-40 my-10 md:my-20'>
            <div className='flex items-center gap-x-8 mb-7 md:mb-10'>
                <div onClick={() => handleChangeTab(1)}>
                    <span className={ toggle === 1 ? "careerActive" : "non__active" } >Job Details</span>
                </div>
                <div onClick={() => handleChangeTab(2)}>
                    <span className={ toggle === 2 ? "careerActive" : "non__active" }>Apply</span>
                </div>
            </div>

            <div className='careerContent'>
                <div className={`${toggle === 1 ? "show__content" : "content"} mt-7 md:mt-10`} >
                    <div className='md:flex gap-x-10 lg:gap-x-14 '>
                        <div className='w-full md:w-9/12 lg:w-4/5'>
                            <div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Title</span><span>: PR Marketing and Profile Maintenance Analyst</span>
                                </div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Experience</span><span>: 2 to 4 years</span>
                                </div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Education</span><span>: Any Degree</span>
                                </div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Cost to the company</span><span>: 3 Lakh-4 Lakh</span>
                                </div>
                            </div>
                            
                            <div className=''>
                                <div className='careerContent__list'>
                                    <span className='block careerContent__subTitle my-6'>Roles and Responsibilities of a Pr marketing and Profile Maintenance Analyst:</span>
                                    <ul className='careerContent__list pl-10'>
                                        <li><span className='careerContent__subTitle'>Profile Management: </span><span>Ensure the accuracy and completeness of profiles across platforms (e.g., LinkedIn, business directories, Google My Business).</span></li>
                                        <li><span className='careerContent__subTitle'>Content Updates: </span><span>Regularly update profiles with new content, achievements, and company developments.</span></li>
                                        <li><span className='careerContent__subTitle'>SEO Optimization: </span><span>Optimize profiles for search engines by using relevant keywords and metadata to enhance visibility.</span></li>
                                        <li><span className='careerContent__subTitle'>Brand Consistency: </span><span>Ensure brand consistency across all online profiles, maintaining up-to-date information and visuals.</span></li>
                                        <li><span className='careerContent__subTitle'>Data Analysis: </span><span>Monitor profile performance metrics, such as views and engagement, and provide insights for improvements.</span></li>
                                        <li><span className='careerContent__subTitle'>Reputation Management:</span><span>Track and respond to customer reviews, comments, and queries on public profiles to maintain a positive brand image.</span></li>
                                        <li><span className='careerContent__subTitle'>Competitor Profile Monitoring: </span><span>Analyze competitors' profiles to benchmark and improve your company’s presence.</span></li>
                                        <li><span className='careerContent__subTitle'>Platform Updates: </span><span>Stay updated on platform changes and features to ensure profiles are fully optimized.</span></li>
                                        <li><span className='careerContent__subTitle'>Media Monitoring: </span> <span>Track media coverage, brand mentions, and industry news to gauge public perception.</span></li>
                                        <li><span className='careerContent__subTitle'>PR Strategy Development: </span><span> Assist in creating PR strategies to enhance brand visibility and reputation.</span></li>
                                        <li><span className='careerContent__subTitle'>Press Releases & Communication: </span><span>Draft press releases, media pitches, and communication materials to support campaigns and company news.</span></li>
                                        <li><span className='careerContent__subTitle'>Crisis Management Support: </span><span>Monitor and analyze negative media coverage, providing insights for managing public relations crises.</span></li>
                                        <li><span className='careerContent__subTitle'>Event Coordination: </span><span>Assist in planning and coordinating media events, press conferences, and public appearances.</span></li>
                                        <li><span className='careerContent__subTitle'>Analytics & Reporting: </span><span>Track and analyze PR campaign performance using metrics like media reach, engagement, and sentiment.</span></li>
                                        <li><span className='careerContent__subTitle'>Stakeholder Engagement: </span><span>Build and maintain relationships with journalists, influencers, and key stakeholders.</span></li>
                                        <li><span className='careerContent__subTitle'>Competitor Analysis: </span><span>Analyze competitors' PR activities and media coverage to identify opportunities and improve strategies.</span></li>
                                    </ul>
                                </div>
        
                                <div className='careerContent__para mt-10 block'>Both roles focus on maintaining a brand's positive image, 
with PR Marketing Analysts managing public perception and media interactions, 
and Profile Maintenance Analysts ensuring accuracy and optimization of digital profiles.</div>
                            </div>
                        </div> 
                    
                        <div className='w-full grid place-content-center lg:block jobDetails__contentLeft md:w-1/5 mt-10 md:mt-0'>
                            <div className='w-full grid place-content-center lg:block'>
                                <div onClick={() => handleChangeTab(2)}>
                                    <button className={ toggle === 2 ? "" : "job__applyBtn" }>Apply</button>
                                </div>
                                <div className='my-4'>
                                    <button className='career__ShareBtn'>Share job</button>
                                </div>
                            </div>                            
                            <div className='flex flex-wrap gap-y-4 gap-x-5'>
                                <div className='flex items-center gap-x-2'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <g clip-path="url(#clip0_2556_2257)">
                                          <path d="M21.507 10.1841L12.507 1.93412C12.3687 1.80721 12.1877 1.7368 12 1.7368C11.8123 1.7368 11.6313 1.80721 11.493 1.93412L2.493 10.1841C2.4164 10.2544 2.35525 10.3398 2.31342 10.435C2.27159 10.5301 2.24999 10.6329 2.25 10.7369V20.8116C2.25099 21.7198 2.61218 22.5904 3.25433 23.2325C3.89647 23.8747 4.76712 24.2359 5.67525 24.2369H18.3248C19.2329 24.2359 20.1035 23.8747 20.7457 23.2325C21.3878 22.5904 21.749 21.7198 21.75 20.8116V10.7369C21.75 10.6329 21.7284 10.5301 21.6866 10.435C21.6448 10.3398 21.5836 10.2544 21.507 10.1841ZM20.25 20.8116C20.2496 21.3221 20.0466 21.8116 19.6857 22.1725C19.3247 22.5335 18.8352 22.7365 18.3248 22.7369H5.67525C5.16476 22.7365 4.6753 22.5335 4.31433 22.1725C3.95336 21.8116 3.7504 21.3221 3.75 20.8116V11.0669L12 3.50388L20.25 11.0669V20.8116Z" fill="#929292"/>
                                          <path d="M8.25 15.9869V19.7369C8.25 19.9358 8.32902 20.1266 8.46967 20.2672C8.61032 20.4079 8.80109 20.4869 9 20.4869C9.19891 20.4869 9.38968 20.4079 9.53033 20.2672C9.67098 20.1266 9.75 19.9358 9.75 19.7369V15.9869C9.75 15.3901 9.98705 14.8178 10.409 14.3959C10.831 13.9739 11.4033 13.7369 12 13.7369C12.5967 13.7369 13.169 13.9739 13.591 14.3959C14.0129 14.8178 14.25 15.3901 14.25 15.9869V19.7369C14.25 19.9358 14.329 20.1266 14.4697 20.2672C14.6103 20.4079 14.8011 20.4869 15 20.4869C15.1989 20.4869 15.3897 20.4079 15.5303 20.2672C15.671 20.1266 15.75 19.9358 15.75 19.7369V15.9869C15.75 14.9923 15.3549 14.0385 14.6517 13.3352C13.9484 12.632 12.9946 12.2369 12 12.2369C11.0054 12.2369 10.0516 12.632 9.34835 13.3352C8.64509 14.0385 8.25 14.9923 8.25 15.9869Z" fill="#929292"/>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_2556_2257">
                                            <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </span>
                                    <span className='career__remote inline-block'>Remote</span>
                                </div>
                                <div className='flex items-center gap-x-2'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <g clip-path="url(#clip0_2556_2240)">
                                            <path d="M21.9529 17.8137C21.6125 17.6267 21.185 17.7511 20.9981 18.0914C20.8111 18.4317 20.9355 18.8592 21.2758 19.0462C22.1134 19.5063 22.5938 20.0164 22.5938 20.4457C22.5938 20.9707 21.8498 21.7845 19.7619 22.4938C17.6992 23.1947 14.9426 23.5806 12 23.5806C9.05742 23.5806 6.30084 23.1947 4.23811 22.4938C2.15016 21.7845 1.40625 20.9707 1.40625 20.4457C1.40625 20.0164 1.88663 19.5063 2.72419 19.0461C3.06455 18.8592 3.18886 18.4317 3.00187 18.0913C2.81489 17.751 2.38753 17.6267 2.04708 17.8136C1.11323 18.3267 0 19.1998 0 20.4457C0 21.4007 0.656813 22.7623 3.78572 23.8254C5.99034 24.5744 8.90756 24.9869 12 24.9869C15.0924 24.9869 18.0097 24.5744 20.2143 23.8254C23.3432 22.7623 24 21.4007 24 20.4457C24 19.1998 22.8868 18.3267 21.9529 17.8137Z" fill="#929292"/>
                                            <path d="M6.6922 21.4773C8.11955 21.8806 10.0048 22.1027 12.0007 22.1027C13.9966 22.1027 15.8819 21.8806 17.3092 21.4773C19.0554 20.9841 19.9409 20.27 19.9409 19.3551C19.9409 18.4401 19.0554 17.7261 17.3092 17.2329C16.9215 17.1234 16.5 17.0273 16.0517 16.9454C15.8083 17.3659 15.5533 17.7988 15.2866 18.2442C15.7827 18.3186 16.2485 18.4099 16.6715 18.5177C17.9566 18.845 18.4261 19.2135 18.5227 19.3551C18.4261 19.4968 17.9567 19.8653 16.6716 20.1926C15.4552 20.5024 13.8902 20.6791 12.242 20.6949C12.1621 20.7008 12.0817 20.7041 12.0007 20.7041C11.9197 20.7041 11.8393 20.7008 11.7594 20.6949C10.1111 20.6791 8.54616 20.5024 7.3298 20.1926C6.04467 19.8653 5.57522 19.4968 5.4787 19.3551C5.57522 19.2135 6.04472 18.845 7.32984 18.5177C7.75284 18.4099 8.21864 18.3186 8.71481 18.2442C8.44814 17.7989 8.19309 17.3659 7.94967 16.9454C7.50145 17.0274 7.07981 17.1234 6.6922 17.2329C4.94597 17.7261 4.06055 18.4402 4.06055 19.3551C4.06055 20.27 4.94597 20.984 6.6922 21.4773Z" fill="#929292"/>
                                            <path d="M11.9991 19.2978C12.6251 19.2978 13.1936 18.9789 13.5201 18.4447C15.8081 14.7014 18.5354 9.76797 18.5354 7.52308C18.5354 3.919 15.6033 0.986877 11.9991 0.986877C8.39502 0.986877 5.46289 3.919 5.46289 7.52308C5.46289 9.76797 8.19022 14.7014 10.4781 18.4447C10.8047 18.9789 11.3733 19.2978 11.9991 19.2978ZM9.37194 7.0743C9.37194 5.62572 10.5505 4.44719 11.9991 4.44719C13.4478 4.44719 14.6263 5.62572 14.6263 7.0743C14.6263 8.52292 13.4478 9.70146 11.9991 9.70146C10.5505 9.70146 9.37194 8.52297 9.37194 7.0743Z" fill="#929292"/>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_2556_2240">
                                              <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                    </span>
                                    <span className='career__remote inline-block'>Chennai</span>
                                </div>
                            </div>
                        </div>   
                    </div>                
                </div>

                <div className={ toggle === 2 ? "show__content" : "content" }>
                    <div className='flex justify-between mt-10'>
                        <div className='w-full md:w-1/2'>
                            <span className='careerPage__applyNow block'>Apply now</span>
                                <div className='mt-16'>
                                <form className="career__form" onSubmit={handleForm}>
      <input
        type="text"
        name="name"
        value={formDetail.name}
        placeholder="Name"
        className="px-5"
        onChange={handleInput}
      />
      {formError.name && <span className="mt-2 payout__errorMsg block"> {formError.name} </span>}

      <input
        type="text"
        name="email"
        value={formDetail.email}
        placeholder="Email"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.email && <span className="mt-2 payout__errorMsg block"> {formError.email} </span>}

      <input
        type="text"
        name="phonenumber"
        value={formDetail.phonenumber}
        placeholder="Phone number"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.phonenumber && <span className="mt-2 payout__errorMsg block"> {formError.phonenumber} </span>}

      <input
        type="text"
        name="jobtitle"
        value={formDetail.jobtitle}
        placeholder="Job Title"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.jobtitle && <span className="mt-2 payout__errorMsg block"> {formError.jobtitle} </span>}

      <input
        type="text"
        name="notice"
        value={formDetail.notice}
        placeholder="Notice period (In days)"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.notice && <span className="mt-2 payout__errorMsg block"> {formError.notice} </span>}

      <input
        type="text"
        name="currentsalary"
        value={formDetail.currentsalary}
        placeholder="Current salary"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.currentsalary && <span className="mt-2 payout__errorMsg block"> {formError.currentsalary} </span>}

      <input
        type="text"
        name="expectedsalary"
        value={formDetail.expectedsalary}
        placeholder="Expected salary"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.expectedsalary && <span className="mt-2 payout__errorMsg block"> {formError.expectedsalary} </span>}

      <div className="career__file grid place-content-center mt-4">
        <input
          type="file"
          id="careerFile"
          style={{ display: "none" }}
          onChange={handleFileChange}
          onClick={(e) => (e.target.value = null)}
        />
        <label htmlFor="careerFile">
          {careerFile ? (
            <span>{careerFile.name}</span>
          ) : (
            <span className="career__upload cursor-pointer text-center block">Upload resume</span>
          )}
          <span className="career__documentFile text-center block mt-1">*Only PDF files are allowed</span>
        </label>
      </div>
      {careerFileError && <span className="mt-2 payout__errorMsg block">{careerFileError}</span>}

      <div className="grid place-content-center mt-5">
        <div className="careerForm__btn">
            {loader ? <button className='flex justify-center items-center gap-x-2 loader__btn'>
                                <div className='circleReport'></div>
                                <div className='circleReport1'></div>
                                <div className='circleReport2'></div>
                            </button> : 
          <button type="submit">Apply</button>  }
        </div>
      </div>
    </form>
                                </div>
                            </div>                        
                            <div className='w-2/5 hidden md:grid place-content-center'>
                                <div className='careerPage__img'>
                                    <img src='\images\careerPage__img.png' alt='img' />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>

        <Nextstep />

        <Footer />

    </div>
  )
}

export default PrMarketting