import { Toaster, toast } from "react-hot-toast"

export const ToastSuccess = (msg) => {
    toast.success(msg)
}

export const ToastError = (msg) => {
    toast.error(msg)
}

export const ToastPromise = (msg) => {
    toast.promise(msg)
}