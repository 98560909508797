import React from 'react'
import './pricing.css'
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'


const Pricing = () => {
  return (
    <>
    <Navbar />
    <div>
    <div className='pricing__bgImage flex justify-center py-10 sm:py-20 px-10 2xl:px-20'>
           <div className='container lg:flex justify-center items-center'>
               <div className='lg:w-1/2 w-full flex justify-center items-center '>
                    <div className='Banner-page text-center lg:text-left pl-60'>
                        <div className='pricing__heading pb-5 md:pb-10'>Lowest Payment service provider charges in India</div>
                        <div className='pricing__body_text md:px-40 lg:px-0'>Empower your business with India's cost-effective payment gateway solution. Say yes to savings and reliability with our unbeatable rates. Experience seamless transactions without compromising on quality</div>
                    </div>
               </div>
               <div className='lg:w-1/2 w-full flex justify-center lg:justify-end'>
                  <div className='px-5 py-10'>
                    <img className='PricingImage-1 rounded-lg' src='\images\priceBanner__img.png' alt='Image of pricing' />
                  </div>
               </div>
           </div>
        </div>
    </div>
      
        <div className='Yellow_purple_page'>
           <div className='lg:flex'>
              <div className='yellow-page w-full my-auto lg:w-1/2 py-5 sm:py-10 md:py-20 flex items-center justify-center'>
                <div className='mx-auto px-5'>
                    <div className='upiprincing__heading pb-5 mt-10'>UPI Collection at 0.9%</div>
                    <div className='upiprincing___heading mb-10'>What Benefits Will You Get</div>
                    <div>
                        <ul className='body_text_yellow'>
                        <div className='flex items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                             <div><li className='px-5 list-none	upiprincing___content'>Free Consulting With Experet Saving Money</li></div>
                        </div>
                        <div className='flex items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                             <div><li className='px-5 list-none	upiprincing___content'>Online Banking</li></div>
                        </div>
                        <div className='flex items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                             <div><li className='px-5 list-none	upiprincing___content'>Investment. Report Every Month</li></div>
                        </div>
                        <div className='flex items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                             <div><li className='px-5 list-none	upiprincing___content'>Saving Money For The Future</li></div>
                        </div>
                        <div className='flex items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                             <div><li className='px-5 list-none	upiprincing___content'>Online Transaction</li></div>
                        </div> 
                        </ul>
                    </div>
                </div>
              </div>
              <div className='w-full my-auto purple-page px-10  lg:w-1/2 flex justify-center items-center py-20'>

                    <img className='profit-image rounded-lg' src='\images\priceImg.png' alt='Profit-image' /> 
    
              </div>
        
       </div>
        </div>

        <Nextstep />
        <Footer />
    </>
  )
}

export default Pricing