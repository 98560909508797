import React, { useState } from 'react'
import "./careerPage.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'
import axios from "axios"
import { AxiosFilePost } from '../utilities/axiospost'
import { AppKey } from '../constant/AppKey'
import { ToastError, ToastSuccess } from '../utilities/toast'

const Backend = () => {

    const [careerFile, setCareerFile] = useState()
    const [toggle, setToggle] = useState(1)
    const [careerFileError, setCareerFileError] = useState()
    const [loader, setLoader] = useState(false)

    const handleChangeTab = (item) => {
        setToggle(item)
    }

    const [formDetail, setFormDetail] = useState({
        name: "",
        email: "",
        phonenumber: "",
        notice: "",
        currentsalary: "",
        expectedsalary: "",
        jobtitle: ""
    })

    const [formError, setFormError] = useState({
        name: "",
        email: "",
        phonenumber: "",
        notice: "",
        currentsalary: "",
        expectedsalary: "",
        jobtitle: ""
    })

    const handleInput = (e) => {
        const { name, value } = e.target
        setFormDetail({ ...formDetail, [name] : value})
        setFormError({ ...formError, [name] : ""})
    }

    const handleValidation = () => {
        let valid = true
        const newError = {}

        if(!formDetail.name){
            valid = false
            newError.name = "Name is required"
        }else if(formDetail.name < 3){
            valid = false
            newError.name = "Minmum 3 letters required"
        }else if(!/^[A-Za-z ]*$/.test(formDetail.name)){
            valid = false
            newError.name = "Only letters are allowed"
        }

        if(!formDetail.email){
            valid = false
            newError.email = "Email id is required"
        }else if(!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formDetail.email)){
            valid = false
            newError.email = "Invalid email address"
        }

        if(!formDetail.phonenumber){
            valid = false
            newError.phonenumber = "Phone number is required"
        }else if(!/^\d{10}$/.test(formDetail.phonenumber)){
            valid = false
            newError.phonenumber = "Invalid phone number"
        }else if(!/^[6789]\d{9}$/.test(formDetail.phonenumber)){
            valid = false
            newError.phonenumber = "Mobile number starting with 6, 7, 8, or 9"
        }

        if(!formDetail.jobtitle){
            valid = false
            newError.jobtitle = "Job title is required"
        }else if(!/^[A-Za-z ]*$/.test(formDetail.jobtitle)){
            valid = false
            newError.jobtitle = "Only letters are allowed"
        }

        if(!formDetail.notice){
            valid = false
            newError.notice = "Notice period is required"
        }else if(!/^[0-9]*$/.test(formDetail.notice)){
            valid = false
            newError.notice = "Only numbers are allowed"
        }

        if(!formDetail.currentsalary){
            valid = false
            newError.currentsalary = "Currentsalary is required"
        }

        if(!formDetail.expectedsalary){
            valid = false
            newError.expectedsalary = "Expected salary is required"
        }

        setFormError(newError)

        return valid;
    
    }


    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.size > 4 * 1024 * 1024) {
                setCareerFileError("File size exceeds 4MB");
                setCareerFile(null);
            } else if (file.type !== "application/pdf") {
                setCareerFileError("Invalid file type. Only PDF files are allowed.");
                setCareerFile(null);
            } else {
                setCareerFileError("");
                setCareerFile(file);
            }
        }
    };
    

    const handleForm = async (e) => {
        e.preventDefault();
    
        if (!handleValidation()) {
          return;
        }
    
        const data = new FormData();
        data.append('email', formDetail.email);
        data.append('attachement', careerFile);
        data.append('name', formDetail.name);
        data.append('jobtitle', formDetail.jobtitle);
        data.append('mobile', formDetail.phonenumber);
        data.append('notice', formDetail.notice);
        data.append('currentsalary', formDetail.currentsalary);
        data.append('exceptedsalary', formDetail.expectedsalary);

        setLoader(true)
    
        await AxiosFilePost(AppKey.CAREERAPI, data)
        .then((res) => {
            if(res.data.statuscode === 200){
                ToastSuccess("Job Apply Successfully!")
                setFormDetail({
                    name: "",
                    email: "",
                    phonenumber: "",
                    notice: "",
                    currentsalary: "",
                    expectedsalary: "",
                    jobtitle: ""
                            
                })
                setCareerFile(null)
            }
        })
        .catch((error) => {
            ToastError("Something went wrong");
        })
        .finally(() => {
            setLoader(false)
        })
    };
    
    

  return (
    <div>

        <Navbar />

        <div className='haodapay__certificateBannerTop'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className='hidden lg:inline-block w-full border-none	outline-none' >
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <video loop autoPlay muted playsinline className='lg:hidden w-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='backend__bannerTitle'>
                    <span className='block text-center'>Director - Operations</span>
                </div>
            </div>        
        </div>

        <div className='px-5 sm:px-10 md:px-20 xl:px-40 my-10 md:my-20'>
            <div className='flex items-center gap-x-8 mb-7 md:mb-10'>
                <div onClick={() => handleChangeTab(1)}>
                    <span className={ toggle === 1 ? "careerActive" : "non__active" } >Job Details</span>
                </div>
                <div onClick={() => handleChangeTab(2)}>
                    <span className={ toggle === 2 ? "careerActive" : "non__active" }>Apply</span>
                </div>
            </div>

            <div className='careerContent'>
                <div className={`${toggle === 1 ? "show__content" : "content"} mt-7 md:mt-10`} >
                    <div className='md:flex gap-x-10 lg:gap-x-14 '>
                        <div className='w-full md:w-9/12 lg:w-4/5'>
                            <div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Title</span><span>: Director - Operations</span>
                                </div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Experience</span><span>: 20+ years</span>
                                </div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Education</span><span>: Any Degree</span>
                                </div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Cost to the company</span><span>: Negotiable</span>
                                </div>
                                <div>
                                    <span className='careerContent__subTitle inline-block w-44'>Reporting to</span><span>: Managing Director</span>
                                </div>
                            </div>
                            <span className='careerContent__para mt-10 block'>Haoda is a developing FinTech company, established in the year of 2021 with registered office
Chennai, India; aspiring significant footprints across the globe in the NBFC domain with sufficient
capital investment.</span>

<span className='careerContent__para my-5 block'>The Company is seeking a qualified director to lead the Non-Bank Financial Institutions Department,
Financial Institutions Group (FIG), which includes IFC's investments and advisory services in non-
banking businesses, including Microfinance, Neobanks & Digital Lenders/Platforms, Distressed
Assets, Imbedded Finance and Credit Funds. The Director will provide leadership and vision to
aggressively grow IFC'ss activities in the non-bank space to expand avenues for financial inclusion. It
also includes providing global knowledge and thought leadership, chairing investment review
meetings, collaborating across the Bank Group on sector strategies, and managing client
relationships.</span>

<span className='careerContent__para block'>The position is based in Chennai, India and reports to the Managing Directors, Haoda Group. The
Director will be an integral member of the leadership team of the company and work very closely
with the other two Directors responsible for Banking & Capital markets and Trade and Supply Chain
Finance. Together, Directors will form the core leadership of IFC's Financial Institutions Group to
deliver on IFC's ambitious growth in debt, equity investments, mobilization, and innovation. The FIG
Leadership will also manage jointly certain cross-cutting themes and staff across the three verticals.</span>
                            <div className=''>
                                <div className='careerContent__list'>
                                    <span className='block careerContent__subTitle my-6'>Roles & Responsibilities:</span>
                                    <ul className='careerContent__list pl-10'>
                                        <li>Provide thought leadership and support business development in various non-bank sub-
sectors including NBFIs, Microfinance, Embedded finance, Insurance, Distressed Assets,
Digital Lenders/Banks, and Credit Funds, with the goal of substantially scaling up FIG's
investments and impact in these areas in a sustainable manner.</li>
                                        <li>Collaborate with the Director - Banking and Capital Markets and Short Term & Supply Chain
Finance with the Regional Industry leadership to direct the activities of multi-disciplinary
global and regional teams and develop investment and advisory strategies that contribute to
enhancing impact in the relevant sub-sectors and in pushing the sustainability and inclusion
agenda.</li>
                                        <li>Chair Investment Review Meetings in the relevant sub-sectors as called for under IFC's
accountability and decision-making (ADM) framework with a view to make high-quality
investment decisions on an informed, timely and comprehensive basis</li>
                                        <li>Provide direct oversight and mentoring for managers and other business heads that will
head specific sub-sectors in the non-banking space; jointly with the Director - Banking and
Capital Markets, provide oversight and support for managers heading up cross-cutting
functions including Equities, Sustainability, Financial Inclusion and Administration.</li>
                                        <li>Build strong partnerships and dialogue with the leadership of other partner global financial
                                        institutions that play a key role in emerging markets.</li>
                                        <li>Drive and oversee the development of new products and innovative approaches for IFC to
invest and engage in the various non-banking spaces, engage closely with other support
functions including Risk to set appropriate risk appetite and deal standards.</li>
                                        <li>Establish and develop close collaboration and maintain partnerships with peers in IBRD/IDA,
MIGA, IMF, and other organizations, including development finance institutions, and engage
with shareholders at senior decision-making levels.</li>
                                    </ul>
                                </div>
        
                                <div>
                                    <span className='block careerContent__subTitle my-6'>Desired Profile:</span>
                                    <ul className='careerContent__list pl-10'>
                                        <li>Master's degree or equivalent professional qualification in finance, business, or equivalent
                                        and a minimum of 20 years of relevant experience in the financial sector.</li>
                                        <li>Strong understanding of the non-bank financial sector with at least 15 years of experience
                                        engaging with, or investing in the space; expertise across multiple sub-sectors will be a plus.</li>
                                        <li>Commitment to development and a proven track record showing alignment with the
                                        banking and IFC's mission, strategy and values.</li>
                                        <li>Strong track record of business delivery, demonstrated investment experience, superior
                                        business judgment, and established professional credibility.</li>
                                        <li>Solid understanding of broader financial markets and products and assessing regulatory
                                        frameworks and trends and how that might impact or create opportunities for IFC.</li>
                                        <li>Strong technical understanding of the investment and advisory businesses, including product
development, structuring, client relationship management, credit and equity skills, and
superior risk management.</li>
                                        <li>A track record of innovation in developing and structuring products to support investments
                                        in emerging markets.</li>
                                        <li>Proven credibility with credit and risk functions, having effectively chaired credit and equity
                                        investment review committees.</li>
                                        <li>Ability and willingness to make decisions, take accountability, and hold others accountable
                                        for results.</li>

                                        <li>Proven track record of managing and motivating staff across different locations, making staff
                                        development a high priority</li>
                                        <li>Demonstrated ability to assemble diverse, high-performing teams in a dynamic and
                                        multicultural environment</li>
                                        <li>Capacity to build strong client relationships that lead to results-oriented and development-
                                        focused partnerships.</li>
                                        <li>Excellent people management skills and openness to feedback and new ideas, and ability to
                                        guide staff to solutions.</li>
                                        <li>Recognized as a team player, leader, strategic thinker, and thought leader, who models the
                                        highest ethical standards in his/her actions.</li>
                                        <li>Strong written and verbal communication skills.</li>
                                        <li>Ability to represent IFC externally and to develop and manage relationships with clients,
                                        governments, media, donors, and other stakeholders.</li>
                                        <li>Meet WBG-wide managerial competencies - Courage of your conviction, Leading the Team
for Impact, Influencing Across Boundaries, Fostering Openness to New Ideas, Building Talent
for the Future.</li>

                                    </ul>
                                </div>
                            </div>
                        </div> 
                    
                        <div className='w-full grid place-content-center lg:block jobDetails__contentLeft md:w-1/5 mt-10 md:mt-0'>
                            <div className='w-full grid place-content-center lg:block'>
                                <div onClick={() => handleChangeTab(2)}>
                                    <button className={ toggle === 2 ? "" : "job__applyBtn" }>Apply</button>
                                </div>
                                <div className='my-4'>
                                    <button className='career__ShareBtn'>Share job</button>
                                </div>
                            </div>                            
                            <div className='flex flex-wrap gap-y-4 gap-x-5'>
                                <div className='flex items-center gap-x-2'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                        <g clip-path="url(#clip0_2556_2257)">
                                          <path d="M21.507 10.1841L12.507 1.93412C12.3687 1.80721 12.1877 1.7368 12 1.7368C11.8123 1.7368 11.6313 1.80721 11.493 1.93412L2.493 10.1841C2.4164 10.2544 2.35525 10.3398 2.31342 10.435C2.27159 10.5301 2.24999 10.6329 2.25 10.7369V20.8116C2.25099 21.7198 2.61218 22.5904 3.25433 23.2325C3.89647 23.8747 4.76712 24.2359 5.67525 24.2369H18.3248C19.2329 24.2359 20.1035 23.8747 20.7457 23.2325C21.3878 22.5904 21.749 21.7198 21.75 20.8116V10.7369C21.75 10.6329 21.7284 10.5301 21.6866 10.435C21.6448 10.3398 21.5836 10.2544 21.507 10.1841ZM20.25 20.8116C20.2496 21.3221 20.0466 21.8116 19.6857 22.1725C19.3247 22.5335 18.8352 22.7365 18.3248 22.7369H5.67525C5.16476 22.7365 4.6753 22.5335 4.31433 22.1725C3.95336 21.8116 3.7504 21.3221 3.75 20.8116V11.0669L12 3.50388L20.25 11.0669V20.8116Z" fill="#929292"/>
                                          <path d="M8.25 15.9869V19.7369C8.25 19.9358 8.32902 20.1266 8.46967 20.2672C8.61032 20.4079 8.80109 20.4869 9 20.4869C9.19891 20.4869 9.38968 20.4079 9.53033 20.2672C9.67098 20.1266 9.75 19.9358 9.75 19.7369V15.9869C9.75 15.3901 9.98705 14.8178 10.409 14.3959C10.831 13.9739 11.4033 13.7369 12 13.7369C12.5967 13.7369 13.169 13.9739 13.591 14.3959C14.0129 14.8178 14.25 15.3901 14.25 15.9869V19.7369C14.25 19.9358 14.329 20.1266 14.4697 20.2672C14.6103 20.4079 14.8011 20.4869 15 20.4869C15.1989 20.4869 15.3897 20.4079 15.5303 20.2672C15.671 20.1266 15.75 19.9358 15.75 19.7369V15.9869C15.75 14.9923 15.3549 14.0385 14.6517 13.3352C13.9484 12.632 12.9946 12.2369 12 12.2369C11.0054 12.2369 10.0516 12.632 9.34835 13.3352C8.64509 14.0385 8.25 14.9923 8.25 15.9869Z" fill="#929292"/>
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_2556_2257">
                                            <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    </span>
                                    <span className='career__remote inline-block'>Remote</span>
                                </div>
                                <div className='flex items-center gap-x-2'>
                                    <span>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                                            <g clip-path="url(#clip0_2556_2240)">
                                            <path d="M21.9529 17.8137C21.6125 17.6267 21.185 17.7511 20.9981 18.0914C20.8111 18.4317 20.9355 18.8592 21.2758 19.0462C22.1134 19.5063 22.5938 20.0164 22.5938 20.4457C22.5938 20.9707 21.8498 21.7845 19.7619 22.4938C17.6992 23.1947 14.9426 23.5806 12 23.5806C9.05742 23.5806 6.30084 23.1947 4.23811 22.4938C2.15016 21.7845 1.40625 20.9707 1.40625 20.4457C1.40625 20.0164 1.88663 19.5063 2.72419 19.0461C3.06455 18.8592 3.18886 18.4317 3.00187 18.0913C2.81489 17.751 2.38753 17.6267 2.04708 17.8136C1.11323 18.3267 0 19.1998 0 20.4457C0 21.4007 0.656813 22.7623 3.78572 23.8254C5.99034 24.5744 8.90756 24.9869 12 24.9869C15.0924 24.9869 18.0097 24.5744 20.2143 23.8254C23.3432 22.7623 24 21.4007 24 20.4457C24 19.1998 22.8868 18.3267 21.9529 17.8137Z" fill="#929292"/>
                                            <path d="M6.6922 21.4773C8.11955 21.8806 10.0048 22.1027 12.0007 22.1027C13.9966 22.1027 15.8819 21.8806 17.3092 21.4773C19.0554 20.9841 19.9409 20.27 19.9409 19.3551C19.9409 18.4401 19.0554 17.7261 17.3092 17.2329C16.9215 17.1234 16.5 17.0273 16.0517 16.9454C15.8083 17.3659 15.5533 17.7988 15.2866 18.2442C15.7827 18.3186 16.2485 18.4099 16.6715 18.5177C17.9566 18.845 18.4261 19.2135 18.5227 19.3551C18.4261 19.4968 17.9567 19.8653 16.6716 20.1926C15.4552 20.5024 13.8902 20.6791 12.242 20.6949C12.1621 20.7008 12.0817 20.7041 12.0007 20.7041C11.9197 20.7041 11.8393 20.7008 11.7594 20.6949C10.1111 20.6791 8.54616 20.5024 7.3298 20.1926C6.04467 19.8653 5.57522 19.4968 5.4787 19.3551C5.57522 19.2135 6.04472 18.845 7.32984 18.5177C7.75284 18.4099 8.21864 18.3186 8.71481 18.2442C8.44814 17.7989 8.19309 17.3659 7.94967 16.9454C7.50145 17.0274 7.07981 17.1234 6.6922 17.2329C4.94597 17.7261 4.06055 18.4402 4.06055 19.3551C4.06055 20.27 4.94597 20.984 6.6922 21.4773Z" fill="#929292"/>
                                            <path d="M11.9991 19.2978C12.6251 19.2978 13.1936 18.9789 13.5201 18.4447C15.8081 14.7014 18.5354 9.76797 18.5354 7.52308C18.5354 3.919 15.6033 0.986877 11.9991 0.986877C8.39502 0.986877 5.46289 3.919 5.46289 7.52308C5.46289 9.76797 8.19022 14.7014 10.4781 18.4447C10.8047 18.9789 11.3733 19.2978 11.9991 19.2978ZM9.37194 7.0743C9.37194 5.62572 10.5505 4.44719 11.9991 4.44719C13.4478 4.44719 14.6263 5.62572 14.6263 7.0743C14.6263 8.52292 13.4478 9.70146 11.9991 9.70146C10.5505 9.70146 9.37194 8.52297 9.37194 7.0743Z" fill="#929292"/>
                                          </g>
                                          <defs>
                                            <clipPath id="clip0_2556_2240">
                                              <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                            </clipPath>
                                          </defs>
                                        </svg>
                                    </span>
                                    <span className='career__remote inline-block'>Chennai</span>
                                </div>
                            </div>
                        </div>  
                    </div>                
                </div>

                <div className={ toggle === 2 ? "show__content" : "content" }>
                    <div className='flex justify-between mt-10'>
                        <div className='w-full md:w-1/2'>
                            <span className='careerPage__applyNow block'>Apply now</span>
                                <div className='mt-16'>
                                <form className="career__form" onSubmit={handleForm}>
      <input
        type="text"
        name="name"
        value={formDetail.name}
        placeholder="Name"
        className="px-5"
        onChange={handleInput}
      />
      {formError.name && <span className="mt-2 payout__errorMsg block"> {formError.name} </span>}

      <input
        type="text"
        name="email"
        value={formDetail.email}
        placeholder="Email"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.email && <span className="mt-2 payout__errorMsg block"> {formError.email} </span>}

      <input
        type="text"
        name="phonenumber"
        value={formDetail.phonenumber}
        placeholder="Phone number"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.phonenumber && <span className="mt-2 payout__errorMsg block"> {formError.phonenumber} </span>}

      <input
        type="text"
        name="jobtitle"
        value={formDetail.jobtitle}
        placeholder="Job Title"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.jobtitle && <span className="mt-2 payout__errorMsg block"> {formError.jobtitle} </span>}

      <input
        type="text"
        name="notice"
        value={formDetail.notice}
        placeholder="Notice period (In days)"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.notice && <span className="mt-2 payout__errorMsg block"> {formError.notice} </span>}

      <input
        type="text"
        name="currentsalary"
        value={formDetail.currentsalary}
        placeholder="Current salary"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.currentsalary && <span className="mt-2 payout__errorMsg block"> {formError.currentsalary} </span>}

      <input
        type="text"
        name="expectedsalary"
        value={formDetail.expectedsalary}
        placeholder="Expected salary"
        className="px-5 mt-4"
        onChange={handleInput}
      />
      {formError.expectedsalary && <span className="mt-2 payout__errorMsg block"> {formError.expectedsalary} </span>}

      <div className="career__file grid place-content-center mt-4">
        <input
          type="file"
          id="careerFile"
          style={{ display: "none" }}
          onChange={handleFileChange}
          onClick={(e) => (e.target.value = null)}
        />
        <label htmlFor="careerFile">
          {careerFile ? (
            <span>{careerFile.name}</span>
          ) : (
            <span className="career__upload cursor-pointer text-center block">Upload resume</span>
          )}
          <span className="career__documentFile text-center block mt-1">*Only PDF files are allowed</span>
        </label>
      </div>
      {careerFileError && <span className="mt-2 payout__errorMsg block">{careerFileError}</span>}

      <div className="grid place-content-center mt-5">
        <div className="careerForm__btn">
            {loader ? <button className='flex justify-center items-center gap-x-2 loader__btn'>
                                <div className='circleReport'></div>
                                <div className='circleReport1'></div>
                                <div className='circleReport2'></div>
                            </button> : 
          <button type="submit">Apply</button>  }
        </div>
      </div>
    </form>
                                </div>
                            </div>                        
                            <div className='w-2/5 hidden md:grid place-content-center'>
                                <div className='careerPage__img'>
                                    <img src='\images\careerPage__img.png' alt='img' />
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        </div>

        <Nextstep />

        <Footer />

    </div>
  )
}

export default Backend