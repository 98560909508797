import React, { useState } from 'react'
import "./career.css"
import Navbar from '../nav/navbar'
import { MdOutlineArrowOutward } from "react-icons/md";
import Nextstep from '../nextStep/nextstep';
import Footer from '../footer/footer';


const Career = () => {
    
  const [isHover, setHover] = useState(false)
  const [isHover1, setHover1] = useState(false)
  const [isHover2, setHover2] = useState(false)
  const [isHover3, setHover3] = useState(false)
  const [isHover4, setHover4] = useState(false)
  const [isHover5, setHover5] = useState(false)

  


  return (
    <div>
        <Navbar />

        <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='aboutUs__bannerTitle'>
                    <span className='block text-center'>Career</span>
                </div>
            </div>        
        </div>

        {/* Role section */}

        <section className='my-10 md:my-20'>
            <span className='roles__heading block text-center mb-10 md:mb-16'>Explore Open Roles</span>
            <div className='jobroles__container grid place-content-center grid-cols-1 lg:grid-cols-2 gap-x-12 gap-y-8 md:gap-y-16 px-10 sm:px-20 md:px-32 lg:px-5 xl:px-20 2xl:px-40'>
                
                <a href='/career/director-operations'> <div className='role__content flex justify-between items-center'
                   onMouseEnter={() => setHover(true)}
                   onMouseLeave={() => setHover(false)}
                >
                    <div className=''>
                        <div className='flex items-center gap-x-5'>
                            <span className='role__contentRemote'>remote</span>
                            <div className='flex items-center gap-x-2'>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 24 25" fill="none">
                                      <g clip-path="url(#clip0_2502_2249)">
                                        <path d="M21.9529 17.8137C21.6125 17.6267 21.185 17.7511 20.9981 18.0914C20.8111 18.4318 20.9355 18.8593 21.2758 19.0462C22.1134 19.5063 22.5938 20.0164 22.5938 20.4457C22.5938 20.9707 21.8498 21.7845 19.7619 22.4938C17.6992 23.1947 14.9426 23.5806 12 23.5806C9.05742 23.5806 6.30084 23.1947 4.23811 22.4938C2.15016 21.7845 1.40625 20.9707 1.40625 20.4457C1.40625 20.0164 1.88663 19.5063 2.72419 19.0461C3.06455 18.8592 3.18886 18.4317 3.00187 18.0913C2.81489 17.751 2.38753 17.6267 2.04708 17.8137C1.11323 18.3267 0 19.1998 0 20.4457C0 21.4007 0.656813 22.7623 3.78572 23.8254C5.99034 24.5744 8.90756 24.9869 12 24.9869C15.0924 24.9869 18.0097 24.5744 20.2143 23.8254C23.3432 22.7623 24 21.4007 24 20.4457C24 19.1998 22.8868 18.3267 21.9529 17.8137Z" fill="#929292"/>
                                        <path d="M6.6922 21.4773C8.11955 21.8806 10.0048 22.1027 12.0007 22.1027C13.9966 22.1027 15.8819 21.8806 17.3092 21.4773C19.0554 20.9841 19.9409 20.27 19.9409 19.3551C19.9409 18.4401 19.0554 17.7261 17.3092 17.2329C16.9215 17.1234 16.5 17.0273 16.0517 16.9454C15.8083 17.3659 15.5533 17.7988 15.2866 18.2442C15.7827 18.3186 16.2485 18.4099 16.6715 18.5177C17.9566 18.845 18.4261 19.2135 18.5227 19.3551C18.4261 19.4968 17.9567 19.8653 16.6716 20.1926C15.4552 20.5024 13.8902 20.6791 12.242 20.6949C12.1621 20.7008 12.0817 20.7041 12.0007 20.7041C11.9197 20.7041 11.8393 20.7008 11.7594 20.6949C10.1111 20.6791 8.54616 20.5024 7.3298 20.1926C6.04467 19.8653 5.57522 19.4968 5.4787 19.3551C5.57522 19.2135 6.04472 18.845 7.32984 18.5177C7.75284 18.4099 8.21864 18.3186 8.71481 18.2442C8.44814 17.7989 8.19309 17.3659 7.94967 16.9454C7.50145 17.0274 7.07981 17.1234 6.6922 17.2329C4.94597 17.7261 4.06055 18.4402 4.06055 19.3551C4.06055 20.27 4.94597 20.984 6.6922 21.4773Z" fill="#929292"/>
                                        <path d="M11.9991 19.2978C12.6251 19.2978 13.1936 18.9789 13.5201 18.4447C15.8081 14.7014 18.5354 9.76797 18.5354 7.52308C18.5354 3.919 15.6033 0.986877 11.9991 0.986877C8.39502 0.986877 5.46289 3.919 5.46289 7.52308C5.46289 9.76797 8.19022 14.7014 10.4781 18.4447C10.8047 18.9789 11.3733 19.2978 11.9991 19.2978ZM9.37194 7.0743C9.37194 5.62572 10.5505 4.44719 11.9991 4.44719C13.4478 4.44719 14.6263 5.62572 14.6263 7.0743C14.6263 8.52292 13.4478 9.70146 11.9991 9.70146C10.5505 9.70146 9.37194 8.52297 9.37194 7.0743Z" fill="#929292"/>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2502_2249">
                                          <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                </span>
                                <span className='role__contentRemote'>Chennai</span>   
                            </div>
                        </div>
                        <span className='role__name block mt-2'>Director - Operations</span>
                    </div>

                    <div
                      className={`role__contentArrow ${isHover ? "hover" : ""} grid place-content-center`}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      <MdOutlineArrowOutward className="MdOutlineArrowOutward__career" />
                    </div>                
                </div></a>

                <a href='/career/lead-generation-executive'> <div className='role__content flex justify-between items-center'
                   onMouseEnter={() => setHover1(true)}
                   onMouseLeave={() => setHover1(false)}
                >
                    <div className=''>
                        <div className='flex items-center gap-x-5'>
                            <span className='role__contentRemote'>remote</span>
                            <div className='flex items-center gap-x-2'>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 24 25" fill="none">
                                      <g clip-path="url(#clip0_2502_2249)">
                                        <path d="M21.9529 17.8137C21.6125 17.6267 21.185 17.7511 20.9981 18.0914C20.8111 18.4318 20.9355 18.8593 21.2758 19.0462C22.1134 19.5063 22.5938 20.0164 22.5938 20.4457C22.5938 20.9707 21.8498 21.7845 19.7619 22.4938C17.6992 23.1947 14.9426 23.5806 12 23.5806C9.05742 23.5806 6.30084 23.1947 4.23811 22.4938C2.15016 21.7845 1.40625 20.9707 1.40625 20.4457C1.40625 20.0164 1.88663 19.5063 2.72419 19.0461C3.06455 18.8592 3.18886 18.4317 3.00187 18.0913C2.81489 17.751 2.38753 17.6267 2.04708 17.8137C1.11323 18.3267 0 19.1998 0 20.4457C0 21.4007 0.656813 22.7623 3.78572 23.8254C5.99034 24.5744 8.90756 24.9869 12 24.9869C15.0924 24.9869 18.0097 24.5744 20.2143 23.8254C23.3432 22.7623 24 21.4007 24 20.4457C24 19.1998 22.8868 18.3267 21.9529 17.8137Z" fill="#929292"/>
                                        <path d="M6.6922 21.4773C8.11955 21.8806 10.0048 22.1027 12.0007 22.1027C13.9966 22.1027 15.8819 21.8806 17.3092 21.4773C19.0554 20.9841 19.9409 20.27 19.9409 19.3551C19.9409 18.4401 19.0554 17.7261 17.3092 17.2329C16.9215 17.1234 16.5 17.0273 16.0517 16.9454C15.8083 17.3659 15.5533 17.7988 15.2866 18.2442C15.7827 18.3186 16.2485 18.4099 16.6715 18.5177C17.9566 18.845 18.4261 19.2135 18.5227 19.3551C18.4261 19.4968 17.9567 19.8653 16.6716 20.1926C15.4552 20.5024 13.8902 20.6791 12.242 20.6949C12.1621 20.7008 12.0817 20.7041 12.0007 20.7041C11.9197 20.7041 11.8393 20.7008 11.7594 20.6949C10.1111 20.6791 8.54616 20.5024 7.3298 20.1926C6.04467 19.8653 5.57522 19.4968 5.4787 19.3551C5.57522 19.2135 6.04472 18.845 7.32984 18.5177C7.75284 18.4099 8.21864 18.3186 8.71481 18.2442C8.44814 17.7989 8.19309 17.3659 7.94967 16.9454C7.50145 17.0274 7.07981 17.1234 6.6922 17.2329C4.94597 17.7261 4.06055 18.4402 4.06055 19.3551C4.06055 20.27 4.94597 20.984 6.6922 21.4773Z" fill="#929292"/>
                                        <path d="M11.9991 19.2978C12.6251 19.2978 13.1936 18.9789 13.5201 18.4447C15.8081 14.7014 18.5354 9.76797 18.5354 7.52308C18.5354 3.919 15.6033 0.986877 11.9991 0.986877C8.39502 0.986877 5.46289 3.919 5.46289 7.52308C5.46289 9.76797 8.19022 14.7014 10.4781 18.4447C10.8047 18.9789 11.3733 19.2978 11.9991 19.2978ZM9.37194 7.0743C9.37194 5.62572 10.5505 4.44719 11.9991 4.44719C13.4478 4.44719 14.6263 5.62572 14.6263 7.0743C14.6263 8.52292 13.4478 9.70146 11.9991 9.70146C10.5505 9.70146 9.37194 8.52297 9.37194 7.0743Z" fill="#929292"/>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2502_2249">
                                          <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                </span>
                                <span className='role__contentRemote'>Chennai</span>   
                            </div>
                        </div>
                        <span className='role__name block mt-2'>Lead Generation Executive</span>
                    </div>

                    <div
                      className={`role__contentArrow ${isHover1 ? "hover" : ""} grid place-content-center`}
                      onMouseEnter={() => setHover1(true)}
                      onMouseLeave={() => setHover1(false)}
                    >
                      <MdOutlineArrowOutward className="MdOutlineArrowOutward__career" />
                    </div>                
                </div></a>

                <a href='/career/manager-IT'> <div className='role__content px-10  flex justify-between items-center'
                   onMouseEnter={() => setHover2(true)}
                   onMouseLeave={() => setHover2(false)}
                >
                    <div className=''>
                        <div className='flex items-center gap-x-5'>
                            <span className='role__contentRemote'>remote</span>
                            <div className='flex items-center gap-x-2'>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 24 25" fill="none">
                                      <g clip-path="url(#clip0_2502_2249)">
                                        <path d="M21.9529 17.8137C21.6125 17.6267 21.185 17.7511 20.9981 18.0914C20.8111 18.4318 20.9355 18.8593 21.2758 19.0462C22.1134 19.5063 22.5938 20.0164 22.5938 20.4457C22.5938 20.9707 21.8498 21.7845 19.7619 22.4938C17.6992 23.1947 14.9426 23.5806 12 23.5806C9.05742 23.5806 6.30084 23.1947 4.23811 22.4938C2.15016 21.7845 1.40625 20.9707 1.40625 20.4457C1.40625 20.0164 1.88663 19.5063 2.72419 19.0461C3.06455 18.8592 3.18886 18.4317 3.00187 18.0913C2.81489 17.751 2.38753 17.6267 2.04708 17.8137C1.11323 18.3267 0 19.1998 0 20.4457C0 21.4007 0.656813 22.7623 3.78572 23.8254C5.99034 24.5744 8.90756 24.9869 12 24.9869C15.0924 24.9869 18.0097 24.5744 20.2143 23.8254C23.3432 22.7623 24 21.4007 24 20.4457C24 19.1998 22.8868 18.3267 21.9529 17.8137Z" fill="#929292"/>
                                        <path d="M6.6922 21.4773C8.11955 21.8806 10.0048 22.1027 12.0007 22.1027C13.9966 22.1027 15.8819 21.8806 17.3092 21.4773C19.0554 20.9841 19.9409 20.27 19.9409 19.3551C19.9409 18.4401 19.0554 17.7261 17.3092 17.2329C16.9215 17.1234 16.5 17.0273 16.0517 16.9454C15.8083 17.3659 15.5533 17.7988 15.2866 18.2442C15.7827 18.3186 16.2485 18.4099 16.6715 18.5177C17.9566 18.845 18.4261 19.2135 18.5227 19.3551C18.4261 19.4968 17.9567 19.8653 16.6716 20.1926C15.4552 20.5024 13.8902 20.6791 12.242 20.6949C12.1621 20.7008 12.0817 20.7041 12.0007 20.7041C11.9197 20.7041 11.8393 20.7008 11.7594 20.6949C10.1111 20.6791 8.54616 20.5024 7.3298 20.1926C6.04467 19.8653 5.57522 19.4968 5.4787 19.3551C5.57522 19.2135 6.04472 18.845 7.32984 18.5177C7.75284 18.4099 8.21864 18.3186 8.71481 18.2442C8.44814 17.7989 8.19309 17.3659 7.94967 16.9454C7.50145 17.0274 7.07981 17.1234 6.6922 17.2329C4.94597 17.7261 4.06055 18.4402 4.06055 19.3551C4.06055 20.27 4.94597 20.984 6.6922 21.4773Z" fill="#929292"/>
                                        <path d="M11.9991 19.2978C12.6251 19.2978 13.1936 18.9789 13.5201 18.4447C15.8081 14.7014 18.5354 9.76797 18.5354 7.52308C18.5354 3.919 15.6033 0.986877 11.9991 0.986877C8.39502 0.986877 5.46289 3.919 5.46289 7.52308C5.46289 9.76797 8.19022 14.7014 10.4781 18.4447C10.8047 18.9789 11.3733 19.2978 11.9991 19.2978ZM9.37194 7.0743C9.37194 5.62572 10.5505 4.44719 11.9991 4.44719C13.4478 4.44719 14.6263 5.62572 14.6263 7.0743C14.6263 8.52292 13.4478 9.70146 11.9991 9.70146C10.5505 9.70146 9.37194 8.52297 9.37194 7.0743Z" fill="#929292"/>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2502_2249">
                                          <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                </span>
                                <span className='role__contentRemote'>Chennai</span>   
                            </div>
                        </div>
                        <span className='role__name block mt-2'>AM / Manager - IT</span>
                    </div>

                    <div
                      className={`role__contentArrow ${isHover2 ? "hover" : ""} grid place-content-center`}
                      onMouseEnter={() => setHover2(true)}
                      onMouseLeave={() => setHover2(false)}
                    >
                      <MdOutlineArrowOutward className="MdOutlineArrowOutward__career" />
                    </div>                
                </div></a>


             <a href='/career/marketing-strategist'> <div className='role__content px-10 py-10 flex justify-between items-center'
                   onMouseEnter={() => setHover3(true)}
                   onMouseLeave={() => setHover3(false)}
                >
                    <div className=''>
                        <div className='flex items-center gap-x-5'>
                            <span className='role__contentRemote'>remote</span>
                            <div className='flex items-center gap-x-2'>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 24 25" fill="none">
                                      <g clip-path="url(#clip0_2502_2249)">
                                        <path d="M21.9529 17.8137C21.6125 17.6267 21.185 17.7511 20.9981 18.0914C20.8111 18.4318 20.9355 18.8593 21.2758 19.0462C22.1134 19.5063 22.5938 20.0164 22.5938 20.4457C22.5938 20.9707 21.8498 21.7845 19.7619 22.4938C17.6992 23.1947 14.9426 23.5806 12 23.5806C9.05742 23.5806 6.30084 23.1947 4.23811 22.4938C2.15016 21.7845 1.40625 20.9707 1.40625 20.4457C1.40625 20.0164 1.88663 19.5063 2.72419 19.0461C3.06455 18.8592 3.18886 18.4317 3.00187 18.0913C2.81489 17.751 2.38753 17.6267 2.04708 17.8137C1.11323 18.3267 0 19.1998 0 20.4457C0 21.4007 0.656813 22.7623 3.78572 23.8254C5.99034 24.5744 8.90756 24.9869 12 24.9869C15.0924 24.9869 18.0097 24.5744 20.2143 23.8254C23.3432 22.7623 24 21.4007 24 20.4457C24 19.1998 22.8868 18.3267 21.9529 17.8137Z" fill="#929292"/>
                                        <path d="M6.6922 21.4773C8.11955 21.8806 10.0048 22.1027 12.0007 22.1027C13.9966 22.1027 15.8819 21.8806 17.3092 21.4773C19.0554 20.9841 19.9409 20.27 19.9409 19.3551C19.9409 18.4401 19.0554 17.7261 17.3092 17.2329C16.9215 17.1234 16.5 17.0273 16.0517 16.9454C15.8083 17.3659 15.5533 17.7988 15.2866 18.2442C15.7827 18.3186 16.2485 18.4099 16.6715 18.5177C17.9566 18.845 18.4261 19.2135 18.5227 19.3551C18.4261 19.4968 17.9567 19.8653 16.6716 20.1926C15.4552 20.5024 13.8902 20.6791 12.242 20.6949C12.1621 20.7008 12.0817 20.7041 12.0007 20.7041C11.9197 20.7041 11.8393 20.7008 11.7594 20.6949C10.1111 20.6791 8.54616 20.5024 7.3298 20.1926C6.04467 19.8653 5.57522 19.4968 5.4787 19.3551C5.57522 19.2135 6.04472 18.845 7.32984 18.5177C7.75284 18.4099 8.21864 18.3186 8.71481 18.2442C8.44814 17.7989 8.19309 17.3659 7.94967 16.9454C7.50145 17.0274 7.07981 17.1234 6.6922 17.2329C4.94597 17.7261 4.06055 18.4402 4.06055 19.3551C4.06055 20.27 4.94597 20.984 6.6922 21.4773Z" fill="#929292"/>
                                        <path d="M11.9991 19.2978C12.6251 19.2978 13.1936 18.9789 13.5201 18.4447C15.8081 14.7014 18.5354 9.76797 18.5354 7.52308C18.5354 3.919 15.6033 0.986877 11.9991 0.986877C8.39502 0.986877 5.46289 3.919 5.46289 7.52308C5.46289 9.76797 8.19022 14.7014 10.4781 18.4447C10.8047 18.9789 11.3733 19.2978 11.9991 19.2978ZM9.37194 7.0743C9.37194 5.62572 10.5505 4.44719 11.9991 4.44719C13.4478 4.44719 14.6263 5.62572 14.6263 7.0743C14.6263 8.52292 13.4478 9.70146 11.9991 9.70146C10.5505 9.70146 9.37194 8.52297 9.37194 7.0743Z" fill="#929292"/>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2502_2249">
                                          <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                </span>
                                <span className='role__contentRemote'>Chennai</span>   
                            </div>
                        </div>
                        <span className='role__name block mt-2'>Marketing Strategist</span>
                    </div>

                    <div
                      className={`role__contentArrow ${isHover3 ? "hover" : ""} grid place-content-center`}
                      onMouseEnter={() => setHover3(true)}
                      onMouseLeave={() => setHover3(false)}
                    >
                      <MdOutlineArrowOutward className="MdOutlineArrowOutward__career" />
                    </div>                
                </div> </a>

               <a href='/career/pr-marketing-and-profile-maintenance-analyst'> <div className='role__content px-10  flex justify-between items-center'
                   onMouseEnter={() => setHover4(true)}
                   onMouseLeave={() => setHover4(false)}
                >
                    <div className='w-11/12'>
                        <div className='flex items-center gap-x-5'>
                            <span className='role__contentRemote'>remote</span>
                            <div className='flex items-center gap-x-2'>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 24 25" fill="none">
                                      <g clip-path="url(#clip0_2502_2249)">
                                        <path d="M21.9529 17.8137C21.6125 17.6267 21.185 17.7511 20.9981 18.0914C20.8111 18.4318 20.9355 18.8593 21.2758 19.0462C22.1134 19.5063 22.5938 20.0164 22.5938 20.4457C22.5938 20.9707 21.8498 21.7845 19.7619 22.4938C17.6992 23.1947 14.9426 23.5806 12 23.5806C9.05742 23.5806 6.30084 23.1947 4.23811 22.4938C2.15016 21.7845 1.40625 20.9707 1.40625 20.4457C1.40625 20.0164 1.88663 19.5063 2.72419 19.0461C3.06455 18.8592 3.18886 18.4317 3.00187 18.0913C2.81489 17.751 2.38753 17.6267 2.04708 17.8137C1.11323 18.3267 0 19.1998 0 20.4457C0 21.4007 0.656813 22.7623 3.78572 23.8254C5.99034 24.5744 8.90756 24.9869 12 24.9869C15.0924 24.9869 18.0097 24.5744 20.2143 23.8254C23.3432 22.7623 24 21.4007 24 20.4457C24 19.1998 22.8868 18.3267 21.9529 17.8137Z" fill="#929292"/>
                                        <path d="M6.6922 21.4773C8.11955 21.8806 10.0048 22.1027 12.0007 22.1027C13.9966 22.1027 15.8819 21.8806 17.3092 21.4773C19.0554 20.9841 19.9409 20.27 19.9409 19.3551C19.9409 18.4401 19.0554 17.7261 17.3092 17.2329C16.9215 17.1234 16.5 17.0273 16.0517 16.9454C15.8083 17.3659 15.5533 17.7988 15.2866 18.2442C15.7827 18.3186 16.2485 18.4099 16.6715 18.5177C17.9566 18.845 18.4261 19.2135 18.5227 19.3551C18.4261 19.4968 17.9567 19.8653 16.6716 20.1926C15.4552 20.5024 13.8902 20.6791 12.242 20.6949C12.1621 20.7008 12.0817 20.7041 12.0007 20.7041C11.9197 20.7041 11.8393 20.7008 11.7594 20.6949C10.1111 20.6791 8.54616 20.5024 7.3298 20.1926C6.04467 19.8653 5.57522 19.4968 5.4787 19.3551C5.57522 19.2135 6.04472 18.845 7.32984 18.5177C7.75284 18.4099 8.21864 18.3186 8.71481 18.2442C8.44814 17.7989 8.19309 17.3659 7.94967 16.9454C7.50145 17.0274 7.07981 17.1234 6.6922 17.2329C4.94597 17.7261 4.06055 18.4402 4.06055 19.3551C4.06055 20.27 4.94597 20.984 6.6922 21.4773Z" fill="#929292"/>
                                        <path d="M11.9991 19.2978C12.6251 19.2978 13.1936 18.9789 13.5201 18.4447C15.8081 14.7014 18.5354 9.76797 18.5354 7.52308C18.5354 3.919 15.6033 0.986877 11.9991 0.986877C8.39502 0.986877 5.46289 3.919 5.46289 7.52308C5.46289 9.76797 8.19022 14.7014 10.4781 18.4447C10.8047 18.9789 11.3733 19.2978 11.9991 19.2978ZM9.37194 7.0743C9.37194 5.62572 10.5505 4.44719 11.9991 4.44719C13.4478 4.44719 14.6263 5.62572 14.6263 7.0743C14.6263 8.52292 13.4478 9.70146 11.9991 9.70146C10.5505 9.70146 9.37194 8.52297 9.37194 7.0743Z" fill="#929292"/>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2502_2249">
                                          <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                </span>
                                <span className='role__contentRemote'>Chennai</span>   
                            </div>
                        </div>
                        <span className='role__name block mt-2'>PR Marketing and Profile Maintenance Analyst</span>
                    </div>

                    <div
                      className={`role__contentArrow ${isHover4 ? "hover" : ""} grid place-content-center`}
                      onMouseEnter={() => setHover4(true)}
                      onMouseLeave={() => setHover4(false)}
                    >
                      <MdOutlineArrowOutward className="MdOutlineArrowOutward__career" />
                    </div>                
                </div> </a>

               <a href='/career/tech-support-or-sales-support'> <div className='role__content  flex justify-between items-center'
                   onMouseEnter={() => setHover5(true)}
                   onMouseLeave={() => setHover5(false)}
                >
                    <div className='w-11/12'>
                        <div className='flex items-center gap-x-5'>
                            <span className='role__contentRemote'>remote</span>
                            <div className='flex items-center gap-x-2'>
                                <span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 24 25" fill="none">
                                      <g clip-path="url(#clip0_2502_2249)">
                                        <path d="M21.9529 17.8137C21.6125 17.6267 21.185 17.7511 20.9981 18.0914C20.8111 18.4318 20.9355 18.8593 21.2758 19.0462C22.1134 19.5063 22.5938 20.0164 22.5938 20.4457C22.5938 20.9707 21.8498 21.7845 19.7619 22.4938C17.6992 23.1947 14.9426 23.5806 12 23.5806C9.05742 23.5806 6.30084 23.1947 4.23811 22.4938C2.15016 21.7845 1.40625 20.9707 1.40625 20.4457C1.40625 20.0164 1.88663 19.5063 2.72419 19.0461C3.06455 18.8592 3.18886 18.4317 3.00187 18.0913C2.81489 17.751 2.38753 17.6267 2.04708 17.8137C1.11323 18.3267 0 19.1998 0 20.4457C0 21.4007 0.656813 22.7623 3.78572 23.8254C5.99034 24.5744 8.90756 24.9869 12 24.9869C15.0924 24.9869 18.0097 24.5744 20.2143 23.8254C23.3432 22.7623 24 21.4007 24 20.4457C24 19.1998 22.8868 18.3267 21.9529 17.8137Z" fill="#929292"/>
                                        <path d="M6.6922 21.4773C8.11955 21.8806 10.0048 22.1027 12.0007 22.1027C13.9966 22.1027 15.8819 21.8806 17.3092 21.4773C19.0554 20.9841 19.9409 20.27 19.9409 19.3551C19.9409 18.4401 19.0554 17.7261 17.3092 17.2329C16.9215 17.1234 16.5 17.0273 16.0517 16.9454C15.8083 17.3659 15.5533 17.7988 15.2866 18.2442C15.7827 18.3186 16.2485 18.4099 16.6715 18.5177C17.9566 18.845 18.4261 19.2135 18.5227 19.3551C18.4261 19.4968 17.9567 19.8653 16.6716 20.1926C15.4552 20.5024 13.8902 20.6791 12.242 20.6949C12.1621 20.7008 12.0817 20.7041 12.0007 20.7041C11.9197 20.7041 11.8393 20.7008 11.7594 20.6949C10.1111 20.6791 8.54616 20.5024 7.3298 20.1926C6.04467 19.8653 5.57522 19.4968 5.4787 19.3551C5.57522 19.2135 6.04472 18.845 7.32984 18.5177C7.75284 18.4099 8.21864 18.3186 8.71481 18.2442C8.44814 17.7989 8.19309 17.3659 7.94967 16.9454C7.50145 17.0274 7.07981 17.1234 6.6922 17.2329C4.94597 17.7261 4.06055 18.4402 4.06055 19.3551C4.06055 20.27 4.94597 20.984 6.6922 21.4773Z" fill="#929292"/>
                                        <path d="M11.9991 19.2978C12.6251 19.2978 13.1936 18.9789 13.5201 18.4447C15.8081 14.7014 18.5354 9.76797 18.5354 7.52308C18.5354 3.919 15.6033 0.986877 11.9991 0.986877C8.39502 0.986877 5.46289 3.919 5.46289 7.52308C5.46289 9.76797 8.19022 14.7014 10.4781 18.4447C10.8047 18.9789 11.3733 19.2978 11.9991 19.2978ZM9.37194 7.0743C9.37194 5.62572 10.5505 4.44719 11.9991 4.44719C13.4478 4.44719 14.6263 5.62572 14.6263 7.0743C14.6263 8.52292 13.4478 9.70146 11.9991 9.70146C10.5505 9.70146 9.37194 8.52297 9.37194 7.0743Z" fill="#929292"/>
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_2502_2249">
                                          <rect width="24" height="24" fill="white" transform="translate(0 0.986877)"/>
                                        </clipPath>
                                      </defs>
                                    </svg>
                                </span>
                                <span className='role__contentRemote'>Chennai</span>   
                            </div>
                        </div>
                        <span className='role__name block mt-2'>Tech Support or Sales Support </span>
                    </div>

                    <div
                      className={`role__contentArrow ${isHover5 ? "hover" : ""} grid place-content-center`}
                      onMouseEnter={() => setHover5(true)}
                      onMouseLeave={() => setHover5(false)}
                    >
                      <MdOutlineArrowOutward className="MdOutlineArrowOutward__career" />
                    </div>                
                </div></a>

                
            </div>
        </section>

        <Nextstep />

        <Footer />

    </div>
  )
}

export default Career