import './event.css';
import { FaArrowRight } from "react-icons/fa";
import { Link } from 'react-router-dom';
import Navbar from '../nav/navbar';
import Nextstep from '../nextStep/nextstep';
import Footer from '../footer/footer';

function Event() {
 return (
   <>
      <section>

        <Navbar />
        
        <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='aboutUs__bannerTitle'>
                    <span className='block text-center'>Events</span>
                </div>
            </div>        
        </div>

        



       <div className='container mx-auto flex justify-center flex-wrap lg:flex-nowrap gap-12 my-10 lg:my-20 px-5'>

               
                  
               <div className='md:w-3/5 xl:w-2/5 2xl:w-1/3 p-5 md:p-10 hover:shadow-lg rounded-lg sm:rounded-2xl border shadow-sm cursor-pointer'><a href='/event/money-expo'>
                <div>
                 <img className='event_image rounded-md lg:rounded-2xl' src="\images\moneyExpoImg4.jpg" alt='img' />
                 <div className='paragraph py-1 md:py-3 lg:py-4'>Date: 17th to 18th August 2024</div>
                 <div className='paragraph pb-1 md:pb-3 lg:pb-4'>Location: io World Convention Centre, Mumbai, India</div>
                 <div className='body_heading pb-1 md:pb-3 lg:pb-4'>Money Expo India as Haoda Pay</div>
                 <div className='paragraph pb-1 md:pb-3 lg:pb-4'>Discover the Future of Payments with Haoda Pay at Money Expo India!</div>
                 <Link to=""> <div className='body_heading2 flex items-center'>Read More<FaArrowRight className='ml-2 text-sm'/></div></Link> </div></a>
               </div>

              <div className='md:w-3/5 xl:w-2/5 2xl:w-1/3 hover:shadow-lg p-5 md:p-10 rounded-lg sm:rounded-xl md:rounded-2xl lg:rounded-3xl border shadow-sm'>
              <a href='/event/global-fintech-fest'> <div>
                <img className='event_image rounded-md md:rounded-2xl' src="\images\gffImg1.jpg" alt='img' />
                <div className='paragraph py-1 md:py-3 lg:py-4'>Date: 28th to 30th August 2024 </div>
                <div className='paragraph pb-1 md:pb-3 lg:pb-4'>Location: Jio World Convention Centre, Mumbai</div>
                <div className='body_heading pb-1 md:pb-3 lg:pb-4'>
Haoda pay is excited to announce our Contribution in the Global Financial Fest
</div>
                <div className='body_heading2 flex items-center'><span>Read More</span><FaArrowRight className='ml-2 text-sm' /></div></div></a>
              </div> 
       </div>

       <Nextstep />

       <Footer />
     </section>
   </>
 );
}

export default Event;
