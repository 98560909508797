import './App.css';
import Blog from './component/blog/blog';
import BlogPage from './component/blog/blogPage';
import Career from './component/career/career';
import Certificate from './component/certificate/certificate';
import Clubassociation from './component/clubassociation/clubassociation';
import Contact from './component/contact/contact';
import Healthcare from './component/healthcare/healthcare';
import Homepage from './component/home/homepage';
import Housingsociety from './component/housingSociety/housingsociety';
import News from './component/news/news';
import TabSlider from './component/tap/tap';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Term from './component/term/term';
import About from './component/about/aboutOld';
import Education from './component/education/education';
import Pricing from './component/pricing/pricing';
import Demo from './component/demo/demo';
import Backend from './component/career/backend';
import Event from './component/event/event';
import EventsPage from './component/event/eventpage';
import Payout from './component/payout/payout';
import Blog2 from './component/blog/Blog1';
import Blog5 from './component/blog/Blog5';
import Blog6 from './component/blog/Blog6';
import Blog7 from './component/blog/Blog7';
import Blog8 from './component/blog/Blog8';
import Blog9 from './component/blog/Blog9';
import Blog10 from './component/blog/Blog10';
import Blog11 from './component/blog/Blog11';
import Blog12 from './component/blog/Blog12';
import Blog13 from './component/blog/Blog13';
import Blog14 from './component/blog/Blog14';
import Blog3 from './component/blog/Blog3';
import PartnerPage from './component/partner/partner';
import { Toaster } from "react-hot-toast";
import RecurringPayments from './component/recurring/recurring';
import LeadGenerationExecutive from './component/career/leadGenerationExecutive';
import ManagerIT from './component/career/managerIT';
import MarketingStrategist from './component/career/marketingStrategist';
import PrMarketting from './component/career/prMarketting';
import TechSupport from './component/career/techSupport';
import Gff from './component/event/gff';
import AboutUs from './component/about/aboutUs.js';
import PrivacyPolicy from './component/privacy/Privacy&Policy.jsx';
import InstantPayments from './component/instandPayment/instantPayments.js';
import PaymentLink from './component/paymentLink/paymentLink.js';


function App() {
  return (
    <div className="App">
      <Toaster position="bottom-left" reverseOrder={false} />
      <BrowserRouter>
         <Routes>
           <Route path='/' element={ <Homepage /> } />
           <Route path='/collection/payment-solutions-for-healthcare' element={ <Healthcare /> } />
           <Route path='/contact' element={ <Contact /> } />
           <Route path='/collection/membership-payment-solutions-in-india' element={ <Clubassociation /> } />
           <Route path='/collection/housing-payments-solutions-in-india' element={ <Housingsociety /> } />
           <Route path='/blog' element={ <Blog /> } />
           <Route path='/blogpage' element={ <BlogPage /> } />
           <Route path='/career' element={ <Career /> } />
           <Route path='/news' element={ <News /> } />
           <Route path='/certificate' element={ <Certificate /> } />
           <Route path='/termsandcondition' element={ <Term /> } />
           <Route path='/privacy-and-policy' element={ <PrivacyPolicy /> } />
           <Route path='/instant-payments' element={ <InstantPayments /> } />
           <Route path='/payment-link' element={ <PaymentLink /> } />
           <Route path='/aboutUs' element={ <AboutUs /> } />
           <Route path='/collection/education-payment-providers-in-india' element={ <Education /> } />
           <Route path='/pricings' element={ <Pricing /> } />
           <Route path='/demo' element={ <Demo /> } />
           <Route path='/career/director-operations' element={ <Backend /> } />
           <Route path='/career/lead-generation-executive' element={ <LeadGenerationExecutive />  } />
           <Route path='/career/manager-IT' element={ <ManagerIT /> } />
           <Route path='/career/marketing-strategist' element={ <MarketingStrategist />  } />
           <Route path='/career/pr-marketing-and-profile-maintenance-analyst' element={ <PrMarketting /> } />
           <Route path='/career/tech-support-or-sales-support' element={ <TechSupport /> } />
           <Route path='/event' element={ <Event /> } />
           <Route path='/event/money-expo' element={ <EventsPage /> } />
           <Route path='/event/global-fintech-fest' element={ <Gff /> } />
           <Route path='/payouts-service-provider-in-india' element={ <Payout /> } />
           <Route path='/product/partner' element={ <PartnerPage /> } />
           <Route path='/recurring-payments' element={ <RecurringPayments /> } />
           <Route path='/the-ultimate-guide-to-choosing-the-best-payout-service-in-india' element={<Blog2 />} />
           <Route path='/top-10-payout-solutions-in-india-an-in-depth-guide' element={ <Blog3 />} />
           <Route path='/the-growing-trend-indian-businesses-transitioning-to-bulk-payout-solution' element={ <Blog5 />} />
           <Route path='/the-essential-role-of-payout-service-providers-in-india-digital-economy' element={ <Blog6 />} />
           <Route path='/selecting-the-optimal-payout-service-in-india-a-comprehensive-guide' element={ <Blog7 />} />
           <Route path='/payins-vs-payouts-the-ultimate-guide-to-understanding-both' element={ <Blog8 />} />
           <Route path='/mastering-payouts-essential-insights-on-what-they-are-and-who-can-use-them' element={ <Blog9 />} />
           <Route path='/from-transactions-to-trust-how-e-Commerce-platforms-use-payout-services' element={ <Blog10 />} />
           <Route path='/features-of-the-best-payout-settlement-api-solution-in-india' element={ <Blog11 />} />
           <Route path='/how-payout-services-enhance-employee-satisfaction-and-retention' element={ <Blog12 />} />
           <Route path='/haodaPay-payout-services-your-go-to-guide-for-fast-and-secure-online-money-transfers' element={ <Blog13 />} />
           <Route path='/what-challenges-have-you-faced-with-payout-services-in-your-finance-journey' element={ <Blog14 />} />

         </Routes>
      </BrowserRouter>

      {/* <Homepage /> */}
      {/* <TabSlider /> */}
    </div>
  );
}

export default App;
