import React from 'react'
import "./nextstep.css"
import { FaArrowRight } from "react-icons/fa";


const Nextstep = () => {
  return (
    <div className=''>
        <section class="ready__container py-10 px-5 md:px-0">
            <div class="container mx-auto">
                <span class="block text-center ready__title">Ready to take the <span className='ready__titleNext'>next</span> <span className='ready__titleStep'>step?</span></span>
                <span class="block text-center ready__para mt-6">Manage all your payments, payouts, and transactions in one place.</span>
               <a href='/contact'> <div class="ready__btn flex justify-center items-center my-5 md:my-8">
                    <button className='flex items-center gap-x-1 px-5 py-3'>Reach out 
                        <span className='arrow__container flex justify-center items-center mt-1'><FaArrowRight className='next__arrow block' /></span>
                    </button>
                </div> </a>
                <span class="block text-center ready__para">For expert solutions tailored to your needs.</span>
            </div>
        </section>
    </div>
  )
}

export default Nextstep 
