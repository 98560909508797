import React from 'react'
import "./news.css"
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'

const News = () => {
  return (
    <div>
        <Navbar />

        <div className='haodapay__certificateBannerTop w-full'>
            <div className='aboutUs__bannerVideo w-full'>
                <video loop autoPlay muted playsinline className=' w-full h-full'>
                    <source src='\images\Web 800_1.mp4' type='video/mp4' />
                </video>
                <div className='aboutUs__bannerTitle'>
                    <span className='block text-center'>News</span>
                </div>
            </div>        
        </div>

        <div className='container mx-auto ml:px-20 flex justify-center my-20'>
            <div className='w-2/6 xl:w-1/2 newsLeft__container flex flex-col gap-y-8'>
                <div className='flex gap-x-8'>
                    <div className='newsContent__img'>
                        <img src='\images\newsImg.png' alt='img' />
                    </div>
                    <div>
                        <span className='block newContent__date'>Natali Craig • 1 Jan 2023</span>
                        <div className='flex my-4'>
                            <span className='block newContent__subTitle'>How collaboration makes us designers</span>
                            <span className='block mt-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="#131319" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <span className='block newContent__subPara'>Collaboration can make our teams stronger, and our individual designs better.</span>
                    </div>
                </div>

                <div className='flex gap-x-8'>
                    <div className='newsContent__img'>
                        <img src='\images\newsImg.png' alt='img' />
                    </div>
                    <div>
                        <span className='block newContent__date'>Natali Craig • 1 Jan 2023</span>
                        <div className='flex my-4'>
                            <span className='block newContent__subTitle'>How collaboration makes us designers</span>
                            <span className='block mt-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="#131319" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <span className='block newContent__subPara'>Collaboration can make our teams stronger, and our individual designs better.</span>
                    </div>
                </div>

                <div className='flex gap-x-8'>
                    <div className='newsContent__img'>
                        <img src='\images\newsImg.png' alt='img' />
                    </div>
                    <div>
                        <span className='block newContent__date'>Natali Craig • 1 Jan 2023</span>
                        <div className='flex my-4'>
                            <span className='block newContent__subTitle'>How collaboration makes us designers</span>
                            <span className='block mt-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="#131319" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <span className='block newContent__subPara'>Collaboration can make our teams stronger, and our individual designs better.</span>
                    </div>
                </div>

                <div className='flex gap-x-8'>
                    <div className='newsContent__img'>
                        <img src='\images\newsImg.png' alt='img' />
                    </div>
                    <div>
                        <span className='block newContent__date'>Natali Craig • 1 Jan 2023</span>
                        <div className='flex my-4'>
                            <span className='block newContent__subTitle'>How collaboration makes us designers</span>
                            <span className='block mt-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="#131319" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <span className='block newContent__subPara'>Collaboration can make our teams stronger, and our individual designs better.</span>
                    </div>
                </div>

                <div className='flex gap-x-8'>
                    <div className='newsContent__img'>
                        <img src='\images\newsImg.png' alt='img' />
                    </div>
                    <div>
                        <span className='block newContent__date'>Natali Craig • 1 Jan 2023</span>
                        <div className='flex my-4'>
                            <span className='block newContent__subTitle'>How collaboration makes us designers</span>
                            <span className='block mt-2'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M7 17L17 7M17 7H7M17 7V17" stroke="#131319" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </span>
                        </div>
                        <span className='block newContent__subPara'>Collaboration can make our teams stronger, and our individual designs better.</span>
                    </div>
                </div>
            </div>
            <div className='w-2/6 xl:pl-8 flex flex-col gap-y-8'>
                <span className='newsRight__heading'>Most Read Articles</span>
                
                <div className='flex mt-8 gap-5'>
                    <div className='newsright__img'>
                        <img src='/images/newsRight__img.png' alt='img' />
                    </div>
                    <div>
                        <span className='block newContent__date'>Natali Craig • 1 Jan 2023</span>
                        <span className='block newContentRight__subTitle mt-3'>How collaboration makes us designers</span>
                    </div>
                </div>

                <div className='flex mt-8 gap-5'>
                    <div className='newsright__img'>
                        <img src='/images/newsRight__img.png' alt='img' />
                    </div>
                    <div>
                        <span className='block newContent__date'>Natali Craig • 1 Jan 2023</span>
                        <span className='block newContentRight__subTitle mt-3'>How collaboration makes us designers</span>
                    </div>
                </div>
            </div>
        </div>

        <Nextstep />

        <Footer />
        
    </div>
  )
}

export default News