import React from 'react'
import './education.css'
import Navbar from '../nav/navbar'
import Nextstep from '../nextStep/nextstep'
import Footer from '../footer/footer'


const Education = () => {
  return (
<>
 

    <Navbar />

      <div className='clubAssociation__bannerBg haodapay__certificateBannerTop h-fit'>
             <div className='clubAssociation__BgVideo'>
                <video loop autoPlay muted playsinline className='w-full h-full'>
                  <source src='\images\Web 800_1.mp4' type='video/mp4' className='w-full h-full' />
                </video>
              </div> 
              
              <div className='education_bannerContent container mx-auto 2xl:px-20 lg:flex justify-between items-center flex-wrap lg:flex-nowrap py-14 px-5 xl:px-10'>
                  <div className='lg:w-3/5 lg:pl-5 2xl:pl-0'>
                      <span className='block clubAssociation__BannerTitle text-center lg:text-left'>Your Trusted Partner for Education Payments.</span>
                      <span className='block clubAssociation__BannerPara mt-5 sm:mt-8 text-center lg:text-left'>Developing Your Business and Supporting Student Growth.</span>
                      <a href='https://merchant-beta.mypayhaoda.com/auth/sign-in' target='_blank'> <div className='lg:block flex justify-center mt-5 sm:mt-8'><button className='housingSociety__MainBtn'>Sign Up</button></div> </a>
                  </div>
                  <div className='lg:w-1/2 flex justify-center lg:justify-center items-center mt-10 lg:mt-0 lg:pl-5 2xl:pl-0'>
                    <div className='clubAssociation__img grid place-content-end'>
                        <img src='\images\educationBanner.png' alt='img' />
                    </div>
                  </div>
              </div>
        </div>

        {/* <section className='lg:py-20 py-5'>
        <div className='lg:flex'>
            <div className='lg:w-1/2 flex justify-center'>
              <div className='w-full px-5 sm:px-10 md:px-20 lg:px-5 xl:ml-24 '>
               <div className='py-3 px-1 md:px-5 flex justify-center'><input className='bg-gray-100 text-sm outline-none  md:text-lg rounded-md md:rounded-xl px-1 sm:px-3 lg:px-5 w-full py-3 sm:py-5 2xl:w-3/4' placeholder='Name' type='text' /></div>
               <div className='py-3 px-1 md:px-5 flex justify-center'><input className='bg-gray-100 text-sm outline-none md:text-lg rounded-md md:rounded-xl px-1 sm:px-3 lg:px-5 w-full py-3 sm:py-5 2xl:w-3/4' placeholder='Phone Number' type='number' /></div>
               <div className='py-3 px-1 md:px-5 flex justify-center'><input className='bg-gray-100 text-sm outline-none md:text-lg rounded-md md:rounded-xl px-1 sm:px-3 lg:px-5 w-full py-3 sm:py-5 2xl:w-3/4' placeholder='Work emall' type='email' /></div>
               <div className='py-3 px-1 md:px-5 flex justify-center'><input className='bg-gray-100 text-sm outline-none md:text-lg rounded-md md:rounded-xl px-1 sm:px-3 lg:px-5 w-full py-3 sm:py-5 2xl:w-3/4' placeholder='Password' type='password' /></div>
               <div className='py-3 px-1 md:px-5 flex justify-center'><input className='bg-gray-100 text-sm outline-none md:text-lg rounded-md md:rounded-xl px-1 sm:px-3 lg:px-5 w-full py-3 sm:py-5 2xl:w-3/4' placeholder='  What`s your primary challenge at the Moment? ' type='text' /></div>
               <div className='flex justify-center'><button className='submit_button'>Submit</button></div>
              </div>
               
            </div>
            <div className='lg:w-1/2 flex justify-start xl:pl-28 items-center'>
                <div className='flex justify-center  items-center'>
                    <img className='EducationPhone rounded-xl' src="/images/educationPhn__img.png" alt='' />
                </div>
            </div>
        </div>
     </section> */}

     <section className='purple_page py-5 md:py-8 mb-10 lg:mb-20'>
        <div className=' mx-auto  px-5  container flex justify-center'>
           <div className='education__heading1 text-center w-full '>Haodapay streamlines school payments, accommodating various preferences with secure online tools integrated into education software</div>
        </div>
        
     </section>

     <section className='md:py-10 flex justify-center lg:py-20'>
        <div className='lg:flex justify-center w-5/6 xl:pl-20 2xl:pl-40'>
            <div className='lg:w-1/2 px-2 sm:px-10 md:px-20 2xl:pr-24 lg:px-0'>
                <div className='education__key mb-3 md:mb-5'>Key Features</div>
                <div className='education__body_subheading mb-2 md:mb-5'>What Benefits Will You Get</div>
                <div className='education__body_text flex items-center'>
                    <ul className='box_tick'>
                        <div className='flex items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                            <div><li className='px-5 education__keyPara'>Unified Payment Acceptance: Accept ACH and card payments seamlessly.</li></div>
                        </div>
                        <div className='flex items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                             <div><li className='px-5 education__keyPara'>Omni-channel Processing: Process payments via various channels-in-person, online, phone, mail, or mobile.</li></div>
                        </div>
                        <div className='flex items-center py-2 md:py-5'>
                            <div><svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div>
                            <div><li className='px-5 education__keyPara'>Cost-Effective Solutions: Benefit from education-specific pricing options to reduce payment acceptance costs.</li></div>
                        </div>
                        <div className='flex items-center py-2 md:py-5'>
                            <div><svg xmlns="http://www.w3.org/2000/svg" className='h-10 w-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                           </div> 
                           <div><li className='px-5 education__keyPara'>elnvoicing with One-Click Payment: Streamline invoicing processes with easy click-to-pay functionality.</li></div>
                        </div>
                    </ul>
                </div>
            </div>
            <div className='lg:w-1/2 flex lg:justify-start justify-center px-5 py-5 lg:pt-20 sx:py-10 items-center'>
                <div className='flex justify-center items-center'>
                    <img className='Education_key_Image' src="/images/education_-key.png" alt='' />
                </div>
            </div>
        </div>
    </section>

    <section className='lg:py-20 flex justify-center py-5 md:py-10'> 
        <div className='lg:flex w-5/6 justify-center'>
            
            <div className=' lg:w-1/2 px-2 sm:px-10 order-2 md:px-20 lg:px-0 2xl:px-20 flex justify-start items-center'>
               <div >
                 <div className='education__body_subheading'>Benifits</div>
                   <div className='education__body_text '>
                    <ul className='box_tick'>
                        <div className='flex lg:pr-10 items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                            <div><li className='px-5 education__keyPara'>Unified Payment Acceptance: Accept ACH and card payments seamlessly.</li></div>
                        </div>
                        <div className='flex lg:pr-10 items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                            <div><li className='px-5 education__keyPara'>Omni-channel Processing: Process payments via various channels-in-person, online, phone, mail, or mobile.</li></div>
                        </div>
                        <div className='flex lg:pr-10 items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                            <div><li className='px-5 education__keyPara'>Cost-Effective Solutions: Benefit from education-specific pricing options to reduce payment acceptance costs.</li></div>
                        </div>
                        <div className='flex lg:pr-10 items-center py-2 md:py-5'>
                            <div>
                            <svg xmlns="http://www.w3.org/2000/svg" className='w-10 h-10' viewBox="0 0 57 56" fill="none">
                            <path d="M3.94531 14.9585H48.5473V50.099H3.94531V14.9585Z" fill="#B0EF8F"/>
                            <path d="M23.0933 45.8465L22.8523 45.4358C19.1585 39.1435 9.3453 25.791 9.24665 25.6569L9.10547 25.4652L12.448 22.1625L23.0314 29.5526C29.6955 20.9049 35.912 14.966 39.9672 11.4974C44.4026 7.70348 47.2903 5.95746 47.3193 5.94044L47.3847 5.90125H53.0533L52.5134 6.38209C38.5807 18.792 23.4796 45.1679 23.3288 45.4329L23.0933 45.8465Z" fill="#009045"/></svg> 
                            </div> 
                            <div><li className='px-5 education__keyPara'>elnvoicing with One-Click Payment: Streamline invoicing processes with easy click-to-pay functionality.</li></div>
                        </div>
                    </ul>
                   </div>
               </div>
            </div>

            <div className='lg:w-1/2 py-5 px-5 flex order-1 2xl:pl-32 justify-center items-center'>
               <div className='flex justify-center items-center'>
                    <img className='Education_key_Image' src="/images/education__benefit.png" alt='' />
                </div>
            </div>
        </div>
    </section>

    <section className='py-20'>
        <div className='education__heading text-center'>Reasons Education Platforms Should Integrate Haodapay</div>
        <div className='flex flex-wrap 2xl:px-5'>
            <div className='flex flex-wrap justify-center  items-start'>
               <div className='Logo_box flex justify-center px-10 py-2 sm:py-5'>
                    <div><img className='Education_reason_image py-5' src="/images/education__reasonImg.png" alt='' /></div>
                </div>
                <div className='Logo_box px-2 sm:px-5 md:px-10 flex justify-center md:justify-start items-center md:items-start py-0 sm:py-5'>
                  <div className='flex justify-center items-center flex-col md:inline-block'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
  <g clip-path="url(#clip0_2255_2269)">
    <path d="M1.73337 9.4986C1.59381 12.2413 2.70484 15.1631 4.90908 17.3689L7.22903 15.0489C5.96356 13.7835 5.18787 12.2176 4.98881 10.6955C7.64881 10.6593 9.80131 8.50668 9.83719 5.84668C11.361 6.0453 12.924 6.82219 14.1905 8.08755L16.5105 5.7676C14.3047 3.56325 11.3837 2.45233 8.64019 2.5919C7.73664 1.53982 6.41408 0.858521 4.92188 0.858521C2.20784 0.858521 0 3.06636 0 5.7804C0 7.2726 0.681297 8.59516 1.73337 9.4986Z" fill="#F4B936"/>
    <path d="M4.92188 56.859C6.39166 56.859 7.69759 56.1985 8.60027 55.174C8.75623 55.1824 8.90827 55.2169 9.06511 55.2169C11.6798 55.2169 14.4212 54.0377 16.5104 51.9501L14.1904 49.6301C12.9239 50.8955 11.361 51.6715 9.83697 51.8703C9.80077 49.2105 7.64859 47.0584 4.98881 47.0222C5.18787 45.5001 5.96356 43.9342 7.22903 42.6687L4.90908 40.3488C2.70484 42.5545 1.59381 45.4763 1.73337 48.2191C0.681297 49.1224 0 50.4449 0 51.9371C0 54.6512 2.20784 56.859 4.92188 56.859Z" fill="#F4B936"/>
    <path d="M51.0807 0.858521C49.5885 0.858521 48.2657 1.53982 47.3625 2.5919C44.6167 2.45211 41.6967 3.56304 39.4922 5.7676L41.8121 8.08755C43.0776 6.82208 44.6408 6.04497 46.1655 5.84646C46.2014 8.50646 48.3539 10.6592 51.0139 10.6954C50.8148 12.2174 50.0391 13.7834 48.7736 15.0488L51.0936 17.3688C53.2978 15.163 54.4089 12.2413 54.2693 9.49849C55.3213 8.59516 56.0026 7.2726 56.0026 5.7804C56.0026 3.06636 53.7947 0.858521 51.0807 0.858521Z" fill="#F4B936"/>
    <path d="M54.2692 48.2191C54.4088 45.4764 53.2977 42.5545 51.0935 40.3488L48.7735 42.6687C50.039 43.9342 50.8147 45.5001 51.0138 47.0222C48.354 47.0584 46.2018 49.2108 46.1656 51.8705C44.6407 51.6719 43.0776 50.8956 41.8121 49.6301L39.4922 51.9501C41.5815 54.0377 44.3227 55.2169 46.9375 55.2169C47.0943 55.2169 47.2462 55.1824 47.4023 55.174C48.305 56.1986 49.6109 56.8591 51.0807 56.8591C53.7947 56.8591 56.0026 54.6513 56.0026 51.9373C56.0026 50.4451 55.3213 49.1225 54.2692 48.2191Z" fill="#F4B936"/>
    <path d="M6.64844 27.2179H19.8269C20.1023 17.1813 22.8959 7.42102 28.0028 7.42102C16.7966 7.42102 7.49161 16.224 6.64844 27.2179Z" fill="#392675"/>
    <path d="M28.0017 10.7023C26.4138 10.7023 23.4324 16.6249 23.1367 27.2179H32.8667C32.5711 16.6249 29.5896 10.7023 28.0017 10.7023Z" fill="#392675"/>
    <path d="M28 7.42102C33.1069 7.42102 35.9005 17.1813 36.1759 27.2179H49.3544C48.5112 16.224 39.2062 7.42102 28 7.42102Z" fill="#392675"/>
    <path d="M49.3544 30.4991H36.1759C35.9005 40.5357 33.1069 50.296 28 50.296C39.2062 50.296 48.5112 41.4931 49.3544 30.4991Z" fill="#392675"/>
    <path d="M6.64844 30.4991C7.49161 41.4931 16.7966 50.296 28.0028 50.296C22.8959 50.296 20.1023 40.5357 19.8269 30.4991H6.64844Z" fill="#392675"/>
    <path d="M28.0017 47.0148C29.5896 47.0148 32.5711 41.0921 32.8667 30.4991H23.1367C23.4324 41.0921 26.4138 47.0148 28.0017 47.0148Z" fill="#392675"/>
  </g>
  <defs>
    <clipPath id="clip0_2255_2269">
      <rect width="56" height="56" fill="white" transform="translate(0 0.858521)"/>
    </clipPath>
  </defs>
</svg>                    <div className='education__body_subheading my-4 text-center md:text-left'>No Setup Fees</div>
                    <div className='education__body_text text-center sm:text-left'>Access an all-in-one education payment solution with instant activation for your educational institution, at zero extra cost.</div>
                  </div>
                   
                </div>


                <div className='Logo_box px-2 sm:px-5 md:px-10 flex justify-start items-start py-0 sm:py-5'>
                    <div className='flex justify-center items-center flex-col md:inline-block'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
  <g clip-path="url(#clip0_2255_2284)">
    <path d="M31.2812 15.7335H29.6406V19.0148H31.2812C32.1864 19.0148 32.9219 18.2793 32.9219 17.3741C32.9219 16.469 32.1864 15.7335 31.2812 15.7335Z" fill="black"/>
    <path d="M15.1914 22.2959H17.8389L16.5152 18.7648L15.1914 22.2959Z" fill="black"/>
    <path d="M49.2624 14.6506C48.7375 8.81008 43.8156 4.13977 37.8438 4.13977C35.8751 4.13977 33.9719 4.66466 32.2327 5.61633C29.4438 2.56466 25.6048 0.858521 21.4375 0.858521C13.6609 0.858521 7.2625 7.00529 6.73761 14.6506C2.8 15.9931 0 19.724 0 23.9366C0 29.2945 4.42564 33.7804 9.95312 33.7804H46.0469C51.5122 33.7804 56 29.3142 56 23.9366C56 19.6054 53.1343 15.9632 49.2624 14.6506ZM22.0142 28.7544C21.1539 29.0725 20.2158 28.6346 19.901 27.7947L19.0696 25.5773H13.9615L13.1301 27.7946C12.8113 28.6438 11.8612 29.0651 11.0169 28.7543C10.1694 28.4355 9.73995 27.4901 10.0573 26.6411L14.9791 13.5161C15.4598 12.2376 17.5714 12.2376 18.0521 13.5161L22.974 26.6411C23.2912 27.4902 22.8618 28.4356 22.0142 28.7544ZM31.2812 22.296H29.6406V27.2179C29.6406 28.1247 28.9068 28.8585 28 28.8585C27.0932 28.8585 26.3594 28.1247 26.3594 27.2179V14.0929C26.3594 13.1861 27.0932 12.4523 28 12.4523H31.2812C33.9953 12.4523 36.2031 14.6601 36.2031 17.3741C36.2031 20.0882 33.9953 22.296 31.2812 22.296ZM42.7656 27.2179C42.7656 28.1247 42.0318 28.8585 41.125 28.8585C40.2182 28.8585 39.4844 28.1247 39.4844 27.2179V14.0929C39.4844 13.1861 40.2182 12.4523 41.125 12.4523C42.0318 12.4523 42.7656 13.1861 42.7656 14.0929V27.2179Z" fill="#392675"/>
    <path d="M8.7083 44.6642L10.3489 47.5059C10.802 48.2905 11.8053 48.5595 12.59 48.1064L14.2817 47.1297C16.9159 50.2127 20.5662 52.3916 24.7181 53.1555V55.2179C24.7181 56.124 25.4526 56.8585 26.3587 56.8585H29.6399C30.546 56.8585 31.2806 56.124 31.2806 55.2179V53.1555C35.4324 52.3916 39.0827 50.2127 41.7169 47.1297L43.4086 48.1064C44.1933 48.5595 45.1967 48.2905 45.6497 47.5059L47.2903 44.6642C47.7434 43.8795 47.4746 42.8761 46.6899 42.4231L44.9943 41.4442C45.486 40.0575 45.7564 38.5781 45.9006 37.0616H39.3183C38.5161 42.6202 33.78 46.9054 27.9993 46.9054C22.2186 46.9054 17.4827 42.6202 16.6803 37.0616H10.0982C10.2424 38.5781 10.5126 40.0575 11.0043 41.4442L9.30877 42.4231C8.52412 42.8761 8.25538 43.8795 8.7083 44.6642Z" fill="#F4B936"/>
  </g>
  <defs>
    <clipPath id="clip0_2255_2284">
      <rect width="56" height="56" fill="white" transform="translate(0 0.858521)"/>
    </clipPath>
  </defs>
</svg>                       <div className='education__body_subheading my-4 text-center md:text-left'>Over 50+ Payment Choices</div>
                       <div className='education__body_text text-center sm:text-left'>Empower your customers to pay school fees online using any payment method across India.</div>
                    </div>
                </div>


               <div className='Logo_box px-2 sm:px-5 md:px-10 flex justify-start items-start py-0 sm:py-5'>
                  <div className='flex justify-center items-center flex-col md:inline-block'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
  <g clip-path="url(#clip0_2255_2293)">
    <path d="M36.2021 33.5294C40.1644 33.5294 43.4777 30.7063 44.2402 26.9669H28.1641C28.9266 30.7063 32.2399 33.5294 36.2021 33.5294Z" fill="#F4B936"/>
    <path d="M36.2031 7.27942C26.0889 7.27942 18.1562 15.5255 18.1562 25.3263C18.1562 30.0959 20.0981 34.6702 23.53 38.086L21.7115 40.8257C21.3766 41.3288 21.3462 41.9761 21.633 42.508C21.9181 43.0415 22.474 43.3732 23.0781 43.3732H36.2031C46.1542 43.3732 54.25 35.2773 54.25 25.3263C54.25 15.3752 46.1542 7.27942 36.2031 7.27942ZM39.4844 17.1232H42.7656C43.6725 17.1232 44.4062 17.857 44.4062 18.7638C44.4062 19.6706 43.6725 20.4044 42.7656 20.4044H39.4844C38.5775 20.4044 37.8438 19.6706 37.8438 18.7638C37.8438 17.857 38.5775 17.1232 39.4844 17.1232ZM29.6406 17.1232H32.9219C33.8287 17.1232 34.5625 17.857 34.5625 18.7638C34.5625 19.6706 33.8287 20.4044 32.9219 20.4044H29.6406C28.7338 20.4044 28 19.6706 28 18.7638C28 17.857 28.7338 17.1232 29.6406 17.1232ZM36.2031 36.8107C29.8713 36.8107 24.7188 31.6581 24.7188 25.3263C24.7188 24.4195 25.4525 23.6857 26.3594 23.6857H46.0469C46.9537 23.6857 47.6875 24.4195 47.6875 25.3263C47.6875 31.6581 42.535 36.8107 36.2031 36.8107Z" fill="#F4B936"/>
    <path d="M23.0781 46.6544C21.258 46.6544 19.5966 45.6579 18.7394 44.0541C17.8806 42.4615 17.9704 40.5245 18.9797 39.0072L19.4027 38.3712C16.466 34.6269 14.875 30.0831 14.875 25.3263C14.875 16.1011 21.0476 7.05083 31.1715 4.61679C30.6993 2.39222 28.7227 0.716919 26.3594 0.716919H6.67188C3.95784 0.716919 1.75 2.92476 1.75 5.63879V51.795C1.75 54.5091 3.95784 56.7169 6.67188 56.7169H26.3594C29.0734 56.7169 31.2812 54.5091 31.2812 51.795V46.6544H23.0781ZM13.2344 8.92004C13.2344 8.01322 13.9682 7.27942 14.875 7.27942H18.1562C19.0631 7.27942 19.7969 8.01322 19.7969 8.92004C19.7969 9.82687 19.0631 10.5607 18.1562 10.5607H14.875C13.9682 10.5607 13.2344 9.82687 13.2344 8.92004ZM16.5156 49.9357C15.6096 49.9357 14.875 49.2011 14.875 48.295C14.875 47.3888 15.6096 46.6544 16.5156 46.6544C17.4217 46.6544 18.1562 47.3888 18.1562 48.295C18.1562 49.2011 17.4217 49.9357 16.5156 49.9357Z" fill="#392675"/>
  </g>
  <defs>
    <clipPath id="clip0_2255_2293">
      <rect width="56" height="56" fill="white" transform="translate(0 0.716919)"/>
    </clipPath>
  </defs>
</svg>                    <div className='education__body_subheading my-4 text-center md:text-left'>Flexible Payment Options</div>
                    <div className='education__body_text text-center md:text-left'>With a wide range of payment methods available, including digital wallets, and bank transfers, we ensure convenience and accessibility for all users.</div>
                  </div>
                </div>


                <div className='Logo_box px-2 sm:px-5 md:px-10 flex justify-start items-start py-0 sm:py-5'>
                    <div className='flex justify-center items-center flex-col md:inline-block'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
  <g clip-path="url(#clip0_2255_2313)">
    <path d="M1.73337 9.357C1.59381 12.0997 2.70484 15.0215 4.90908 17.2273L7.22903 14.9073C5.96356 13.6419 5.18787 12.076 4.98881 10.5539C7.64881 10.5177 9.80131 8.36508 9.83719 5.70508C11.361 5.9037 12.924 6.68059 14.1905 7.94595L16.5105 5.626C14.3047 3.42165 11.3837 2.31073 8.64019 2.45029C7.73664 1.39822 6.41408 0.716919 4.92188 0.716919C2.20784 0.716919 0 2.92476 0 5.63879C0 7.131 0.681297 8.45356 1.73337 9.357Z" fill="#F4B936"/>
    <path d="M4.92188 56.7174C6.39166 56.7174 7.69759 56.0569 8.60027 55.0324C8.75623 55.0408 8.90827 55.0753 9.06511 55.0753C11.6798 55.0753 14.4212 53.8961 16.5104 51.8085L14.1904 49.4885C12.9239 50.7539 11.361 51.5299 9.83697 51.7287C9.80077 49.0689 7.64859 46.9168 4.98881 46.8806C5.18787 45.3585 5.96356 43.7926 7.22903 42.5271L4.90908 40.2072C2.70484 42.4129 1.59381 45.3347 1.73337 48.0775C0.681297 48.9808 0 50.3033 0 51.7955C0 54.5096 2.20784 56.7174 4.92188 56.7174Z" fill="#F4B936"/>
    <path d="M51.0807 0.716919C49.5885 0.716919 48.2657 1.39822 47.3625 2.45029C44.6167 2.31051 41.6967 3.42143 39.4922 5.626L41.8121 7.94595C43.0776 6.68048 44.6408 5.90337 46.1655 5.70486C46.2014 8.36486 48.3539 10.5176 51.0139 10.5538C50.8148 12.0758 50.0391 13.6418 48.7736 14.9072L51.0936 17.2272C53.2978 15.0214 54.4089 12.0997 54.2693 9.35689C55.3213 8.45356 56.0026 7.131 56.0026 5.63879C56.0026 2.92476 53.7947 0.716919 51.0807 0.716919Z" fill="#F4B936"/>
    <path d="M54.2692 48.0775C54.4088 45.3348 53.2977 42.4129 51.0935 40.2072L48.7735 42.5271C50.039 43.7926 50.8147 45.3585 51.0138 46.8806C48.354 46.9168 46.2018 49.0692 46.1656 51.7289C44.6407 51.5303 43.0776 50.754 41.8121 49.4885L39.4922 51.8085C41.5815 53.8961 44.3227 55.0753 46.9375 55.0753C47.0943 55.0753 47.2462 55.0408 47.4023 55.0324C48.305 56.057 49.6109 56.7175 51.0807 56.7175C53.7947 56.7175 56.0026 54.5097 56.0026 51.7957C56.0026 50.3034 55.3213 48.9809 54.2692 48.0775Z" fill="#F4B936"/>
    <path d="M6.64844 27.0763H19.8269C20.1023 17.0397 22.8959 7.27942 28.0028 7.27942C16.7966 7.27942 7.49161 16.0824 6.64844 27.0763Z" fill="#392675"/>
    <path d="M28.0017 10.5607C26.4138 10.5607 23.4324 16.4833 23.1367 27.0763H32.8667C32.5711 16.4833 29.5896 10.5607 28.0017 10.5607Z" fill="#392675"/>
    <path d="M28 7.27942C33.1069 7.27942 35.9005 17.0397 36.1759 27.0763H49.3544C48.5112 16.0824 39.2062 7.27942 28 7.27942Z" fill="#392675"/>
    <path d="M49.3544 30.3575H36.1759C35.9005 40.3941 33.1069 50.1544 28 50.1544C39.2062 50.1544 48.5112 41.3515 49.3544 30.3575Z" fill="#392675"/>
    <path d="M6.64844 30.3575C7.49161 41.3515 16.7966 50.1544 28.0028 50.1544C22.8959 50.1544 20.1023 40.3941 19.8269 30.3575H6.64844Z" fill="#392675"/>
    <path d="M28.0017 46.8732C29.5896 46.8732 32.5711 40.9505 32.8667 30.3575H23.1367C23.4324 40.9505 26.4138 46.8732 28.0017 46.8732Z" fill="#392675"/>
  </g>
  <defs>
    <clipPath id="clip0_2255_2313">
      <rect width="56" height="56" fill="white" transform="translate(0 0.716919)"/>
    </clipPath>
  </defs>
</svg>                       <div className='education__body_subheading my-4 text-center md:text-left'>Secure Transactions</div>
                       <div className='education__body_text text-center md:text-left'>Security is our top priority. We utilize advanced encryption and compliance measures to safeguard sensitive financial data and protect against fraud.</div>
                    </div>
                </div>


                <div className='Logo_box px-2 sm:px-5 md:px-10 flex justify-start items-start py-0 sm:py-5'>
                    <div className='flex justify-center items-center flex-col md:inline-block'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="57" viewBox="0 0 56 57" fill="none">
  <g clip-path="url(#clip0_2255_2328)">
    <path d="M31.2812 15.5919H29.6406V18.8732H31.2812C32.1864 18.8732 32.9219 18.1377 32.9219 17.2325C32.9219 16.3274 32.1864 15.5919 31.2812 15.5919Z" fill="black"/>
    <path d="M15.1914 22.1543H17.8389L16.5152 18.6232L15.1914 22.1543Z" fill="black"/>
    <path d="M49.2624 14.509C48.7375 8.66848 43.8156 3.99817 37.8438 3.99817C35.8751 3.99817 33.9719 4.52306 32.2327 5.47473C29.4438 2.42306 25.6048 0.716919 21.4375 0.716919C13.6609 0.716919 7.2625 6.86368 6.73761 14.509C2.8 15.8515 0 19.5824 0 23.795C0 29.1529 4.42564 33.6388 9.95312 33.6388H46.0469C51.5122 33.6388 56 29.1726 56 23.795C56 19.4638 53.1343 15.8216 49.2624 14.509ZM22.0142 28.6128C21.1539 28.9309 20.2158 28.493 19.901 27.6531L19.0696 25.4357H13.9615L13.1301 27.653C12.8113 28.5022 11.8612 28.9235 11.0169 28.6127C10.1694 28.2939 9.73995 27.3485 10.0573 26.4995L14.9791 13.3744C15.4598 12.096 17.5714 12.096 18.0521 13.3744L22.974 26.4995C23.2912 27.3486 22.8618 28.294 22.0142 28.6128ZM31.2812 22.1544H29.6406V27.0763C29.6406 27.9831 28.9068 28.7169 28 28.7169C27.0932 28.7169 26.3594 27.9831 26.3594 27.0763V13.9513C26.3594 13.0445 27.0932 12.3107 28 12.3107H31.2812C33.9953 12.3107 36.2031 14.5185 36.2031 17.2325C36.2031 19.9466 33.9953 22.1544 31.2812 22.1544ZM42.7656 27.0763C42.7656 27.9831 42.0318 28.7169 41.125 28.7169C40.2182 28.7169 39.4844 27.9831 39.4844 27.0763V13.9513C39.4844 13.0445 40.2182 12.3107 41.125 12.3107C42.0318 12.3107 42.7656 13.0445 42.7656 13.9513V27.0763Z" fill="#392675"/>
    <path d="M8.7083 44.5226L10.3489 47.3643C10.802 48.1489 11.8053 48.4179 12.59 47.9648L14.2817 46.9881C16.9159 50.0711 20.5662 52.25 24.7181 53.0139V55.0763C24.7181 55.9824 25.4526 56.7169 26.3587 56.7169H29.6399C30.546 56.7169 31.2806 55.9824 31.2806 55.0763V53.0139C35.4324 52.25 39.0827 50.0711 41.7169 46.9881L43.4086 47.9648C44.1933 48.4179 45.1967 48.1489 45.6497 47.3643L47.2903 44.5226C47.7434 43.7379 47.4746 42.7345 46.6899 42.2815L44.9943 41.3026C45.486 39.9159 45.7564 38.4365 45.9006 36.92H39.3183C38.5161 42.4786 33.78 46.7638 27.9993 46.7638C22.2186 46.7638 17.4827 42.4786 16.6803 36.92H10.0982C10.2424 38.4365 10.5126 39.9159 11.0043 41.3026L9.30877 42.2815C8.52412 42.7345 8.25538 43.7379 8.7083 44.5226Z" fill="#F4B936"/>
  </g>
  <defs>
    <clipPath id="clip0_2255_2328">
      <rect width="56" height="56" fill="white" transform="translate(0 0.716919)"/>
    </clipPath>
  </defs>
</svg>                       <div className='education__body_subheading my-4 text-center md:text-left'>Dedicated Support</div>
                       <div className='education__body_text text-center md:text-left'>Count on us for expert assistance with setup, integration, and troubleshooting</div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <Nextstep />

    <Footer />

 </>
  )
}

export default Education